import {IResponsesCommon} from 'app/types/commonTypes'
import {AxiosResponse} from 'axios'
import fetchApi from '../helpers/fetch-api'
import {ASSISTANT_ROLE, DEALER_ROLE, USER_NORMAL_ROLE} from '../contants'

const CAR_TYPES_URL = `car-types`
const ENGINE_TYPES_URL = `engine-types`
const CAR_LEVELS_URL = `car-levels`
const USERS_URL = `users`
const MANUFACTURER_URL = `manufacturers`
const FINACIALCOMPANY_URL = 'financial-companies'
const CAR_COLOR_URL = 'car-colors'
const CAR_OPTIONS_URL = 'car-options'

export const getCarTypesAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<any>>> => {
  return fetchApi.getData(CAR_TYPES_URL, params)
}

export const getEngineTypesAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<any>>> => {
  return fetchApi.getData(ENGINE_TYPES_URL, params)
}

export const getCarLevelsAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<any>>> => {
  return fetchApi.getData(CAR_LEVELS_URL, {...params, with: 'image;carType.manufacturer'})
}

export const getUsersAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<any>>> => {
  return fetchApi.getData(USERS_URL, {
    ...params,
    role: USER_NORMAL_ROLE,
    status: 1,
  })
}

export const getDealersAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<any>>> => {
  return fetchApi.getData(USERS_URL, {
    ...params,
    role: DEALER_ROLE,
    with: 'manufacturer',
    countType: 'dealer',
    status: 1,
  })
}

export const getAssistantsAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<any>>> => {
  return fetchApi.getData(USERS_URL, {
    ...params,
    role: ASSISTANT_ROLE,
    countType: 'assistant',
    status: 1,
  })
}

export const getManufacturersAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<any>>> => {
  return fetchApi.getData(MANUFACTURER_URL, params)
}

export const getFinancialCompanyAPI = (
  params: any
): Promise<AxiosResponse<IResponsesCommon<any>>> => {
  return fetchApi.getData(FINACIALCOMPANY_URL, params)
}

export const getCarColorsAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<any>>> => {
  return fetchApi.getData(CAR_COLOR_URL, params)
}

export const getCarOptionssAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<any>>> => {
  return fetchApi.getData(CAR_OPTIONS_URL, params)
}

export const getCarColorsByEngineTypesAPI = (
  idEngine: any
): Promise<AxiosResponse<IResponsesCommon<any>>> => {
  return fetchApi.getData(CAR_COLOR_URL, {
    engine_type_id: idEngine,
    with: 'engineTypes',
    limit: 1500
  })
}
