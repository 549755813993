import { call, put, takeLatest} from 'redux-saga/effects'
import {getListCategory, getListCategorySuccess} from './slice'
import {setLoading} from 'app/redux/loadingSlice'
import { getCategoryAPI } from 'app/services/categoryService'

export function* categorySaga() {
  //list
  yield takeLatest(getListCategory.type, function* eventRequested(action: any) {
    const { data } = yield call(getCategoryAPI, action.payload);
    yield put(getListCategorySuccess(data));
  })
}