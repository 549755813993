import {showToastr} from 'app/helpers/toastr'
import {getInstallmentCashAPI} from 'app/services/installmentCashService'
import { IResponsesErrorCommon } from 'app/types/commonTypes'
import {call, put, takeLatest} from 'redux-saga/effects'
import {getInstallmentCash, getInstallmentCashSuccess} from './slice'

export function* installmentCashSaga() {
  yield takeLatest(getInstallmentCash.type, function* installmentCashRequested(action: any) {
    try {
      const {data} = yield call(getInstallmentCashAPI, action.payload)
      yield put(getInstallmentCashSuccess(data))
    } catch (error: unknown) {
      const err = error as IResponsesErrorCommon<any>
      yield showToastr(err.data.meta.message, 'fail')
    }
  })
}
