import {actions} from 'app/modules/auth/redux/AuthRedux'
import {setLoading} from 'app/redux/loadingSlice'

export default function setupAxios(axios: any, store: any) {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: any) => {
      store.dispatch(setLoading(true))
      const {
        auth: {accessToken},
      } = store.getState()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    (response: {data: any}) => {
      store.dispatch(setLoading(false))
      return response
    },
    (error: {response: any}) => {
      const {response} = error
      store.dispatch(setLoading(false))
      if (response?.status === 401) {
        store.dispatch(actions.forceLogout())
      }

      if (response?.status === 403) {
        store.dispatch(actions.forceLogout())
      }      
      return Promise.reject(error.response)
    }
  )
}
