import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IResponsesCommon } from 'app/types/commonTypes'

export interface ICoupon {
  uuid: string,
  name: string,
  user: string,
  status: number,
  issuance_form: string | number,
  type: string | number,
  code: string | number,
  description: string,
  price: number,
  created_at: string,
  expiration_date: string,
  updated_at: string,
  image: string,
}

export interface ICouponStatstical {
  type: string|number,
  num: string|number,
}

export interface ContainerFAQState {
  coupons: IResponsesCommon<ICoupon>
  couponDetail: IResponsesCommon<ICoupon>
  statstical: ICouponStatstical[]
}

export const initialState: ContainerFAQState = {
  coupons: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as ICoupon[]
    }
  } as IResponsesCommon<ICoupon>,

  couponDetail: {
    data: {
      data: {} as ICoupon[]
    }
  } as IResponsesCommon<ICoupon>,
  statstical: []
}

const couponSlice = createSlice({
  name: 'coupon-state',
  initialState,
  reducers: {
    //list
    getListCoupon(state, action: PayloadAction<any>) { },
    getListCouponSuccess: (state, action: PayloadAction<IResponsesCommon<ICoupon>>) => {
      state.coupons = action.payload
    },
    
    //detail
    getDetailCoupon(state, action: PayloadAction<any>) { },
    getDetailCouponSuccess: (state, action: PayloadAction<IResponsesCommon<ICoupon>>) => {
      state.couponDetail = action.payload
    },

    //statstical
    getStatsticalCoupon(state, action: PayloadAction<any>) { },
    getStatsticalCouponSuccess: (state, action: PayloadAction<ICouponStatstical[]>) => {     
      state.statstical = action.payload 
    }
  }
})

export const { getListCoupon, getListCouponSuccess, getDetailCoupon, getDetailCouponSuccess, getStatsticalCoupon, getStatsticalCouponSuccess } = couponSlice.actions

export default couponSlice.reducer
