import { call, put, takeLatest} from 'redux-saga/effects'
import {
  getListUser,
  getListUserSuccess,
  getUserDetail,
  getUserDetailSuccess
} from './slice'
import {
  getUserAPI,
  getUserDetailAPI
} from 'app/services/userService'
import {USER_NORMAL_ROLE} from '../../../../../contants'

export function* userSaga() {
  //list
  yield takeLatest(getListUser.type, function* usersRequested(action: any) {
    try {
      const { data } = yield call(getUserAPI, action.payload, USER_NORMAL_ROLE);
      yield put(getListUserSuccess(data));
    } catch (err) {}
  })

  yield takeLatest(getUserDetail.type, function* userDetailRequested(action: any) {
    try {
      const { data } = yield call(getUserDetailAPI, action.payload, 'rentHistory.carType;rentHistory.carLevel;rentHistory.carType;rentHistory.manufacturer;rentHistory.assistant;rentHistory.engineType')
      yield put(getUserDetailSuccess(data))
    } catch (err) {}
  })
}