import React from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import '../../../../_metronic/assets/sass/page/login.scss'
import {useFormik} from 'formik'
import {login} from '../redux/AuthCRUD'
import * as auth from '../redux/AuthRedux'
import * as Yup from 'yup'
import {useAppDispatch, useAppSelector} from '../../../hooks'
import Loading from '../../../components/layout/Loading'
import {setLoading} from '../../../redux/loadingSlice'
import moment from 'moment'
import {SESSION_EXPIRED} from '../../../Consts'
import {put} from 'redux-saga/effects'
import {actions} from '../redux/AuthRedux'

interface ILogin {
  email: string,
  password: string
}

const initialValues: ILogin = {
  email: '',
  password: '',
}

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('이메일 형식과 맞지 않습니다.')
    .required('아이디를 입력해주세요.'),
  password: Yup.string()
    .min(3, '비밀번호는 최소 3문자로 입력해 주세요.')
    .required('비밀번호를 입력해주세요.'),
})

const Login: React.FC = () => {
  const dispatch = useAppDispatch()
  const { isLoading } = useAppSelector(state => state.loading)
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      dispatch(setLoading(true))
      login(values.email, values.password)
        .then((data) => {
          const loginData = data.data.data
          const {access_token, user, refresh_token} = loginData
          const session_expired = moment().add(SESSION_EXPIRED, 'minutes')
          dispatch(auth.actions.setSessionExpired(session_expired))
          dispatch(auth.actions.login(access_token, user, refresh_token))
        })
        .catch(() => {
          dispatch(setLoading(false))
          setSubmitting(false)
          setStatus('사용자로 등록되지 않은 계정이거나, 아이디 또는 비밀번호를 잘못 입력하셨습니다.')
        })
    },
  })

  return (
    <div className='row no-gutter'>
      <Loading loading={isLoading}/>
      <div className='d-none d-md-flex col-md-6 col-lg-9 bg-image'
           style={{
             backgroundImage: `url('${toAbsoluteUrl('/images/login/login.png')}')`,
           }}
      />
      <div className='col-md-6 col-lg-3 login-wrapper'>
        <div className='login d-flex align-items-center py-5'>
          <div className=''>
            <div className='row'>
              <div className='col-md-9 col-lg-10 mx-auto'>
                <h3 className='login-heading mb-10 login-title font-weight-400'>Log-in</h3>
                <h4 className='mb-5 login-title-2 font-weight-500'>본인의 계정으로 로그인해주세요.</h4>
                <h5 className='mb-20 login-title-3 font-weight-400'>신규 사용자 이시거나, 계정 및 비밀번호 재발급을 원하시는 분들께서는 아래로 연락부탁드립니다. (1800-9149)</h5>
                <form onSubmit={formik.handleSubmit} noValidate>
                  <div className='form-label-group mb-10'>
                    <input
                      {...formik.getFieldProps('email')}
                      placeholder='아이디를 입력해주세요.'
                      className={`form-control login-input ${formik.errors.email ? 'is-invalid' : ''}`}
                      type='text'
                      name='email'
                      maxLength={255}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='invalid-feedback' style={{ display: 'block' }}>
                        <span role='alert'>{formik.errors.email}</span>
                      </div>
                    )}
                  </div>
                  <div className='form-label-group mb-20'>
                    <input
                      {...formik.getFieldProps('password')}
                      placeholder='비밀번호를 입력해주세요.'
                      className={`form-control login-input ${formik.errors.password ? 'is-invalid' : ''}`}
                      type='password'
                      name='password'
                      maxLength={255}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className='invalid-feedback' style={{ display: 'block' }}>
                        <span role='alert'>{formik.errors.password}</span>
                      </div>
                    )}
                  </div>
                  <div className='form-label-group'>
                    <button
                      className='btn btn-lg btn-primary btn-block btn-login text-uppercase'
                      type='submit'
                      disabled={formik.isSubmitting}
                    >
                      로그인
                    </button>
                    {
                      formik.status && (
                        <div className='invalid-feedback' style={{ display: 'block' }}>
                          <span role='alert'>{formik.status}</span>
                        </div>
                      )
                    }
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login