const TYPES_FILTER_DASHBOARD = {
  DATE: 'date',
  MONTH: 'month',
}

const CHART_LEGENDS = {
  RENT: '렌트/리스',
  CASH: '할부/현금',
}
export {TYPES_FILTER_DASHBOARD, CHART_LEGENDS}
