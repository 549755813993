import {IVideo} from 'app/modules/management/other/youtube/redux/slice'
import {IResponsesCommon, IResponsesUpdateCommon} from 'app/types/commonTypes'
import axios, {AxiosResponse} from 'axios'

const VIDEO_URL = `videos`

export const getVideoAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<IVideo>>> => {
  return axios.get(VIDEO_URL, {
    params: {
      ...params,
      orderBy:'created_at',
      sortedBy: 'desc'
    },
  })
}

export const createVideoAPI = (
  data: any
): Promise<AxiosResponse<IResponsesUpdateCommon<IVideo>>> => {
  return axios.post(VIDEO_URL, data)
}

export const updateVideoAPI = (
  id: string,
  data: any
): Promise<AxiosResponse<IResponsesUpdateCommon<IVideo>>> => {
  let requestURL = `${VIDEO_URL}/${id}`
  return axios.put(requestURL, data)
}

export const deleteVideoAPI = (
  data: string[]
): Promise<AxiosResponse<IResponsesUpdateCommon<IVideo>>> => {
  let requestURL = `${VIDEO_URL}/delete-multiple`
  return axios.post(requestURL, {uuid: data})
}
