import axios, {AxiosResponse} from 'axios'
import {IResponsesCommon, IResponsesUpdateCommon} from '../types/commonTypes'
import {IPoint} from '../modules/management/events/point/redux/slice'

const POINT_URL= 'point-histories'

export const getPointsAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<IPoint>>> => {
  return axios.get(POINT_URL, {
    params: {
      ...params,
      with: 'user',
      orderBy: 'created_at',
      sortedBy: 'desc',
    }
  })
}

export const createPointAPI = (data: any): Promise<AxiosResponse<IResponsesUpdateCommon<IPoint>>> => {
  return axios.post(POINT_URL, data)
}

export const updatePointAPI = (uuid: string, data: any): Promise<AxiosResponse<IResponsesUpdateCommon<IPoint>>> => {
  const requestUrl = `${POINT_URL}/${uuid}`
  return axios.put(requestUrl, data)
}

export const deletePointApi = (uuid: string[]): Promise<AxiosResponse<IResponsesUpdateCommon<IPoint>>> => {
  const requestUrl = `${POINT_URL}/delete-multiple`
  return axios.post(requestUrl, {
    uuid
  })
}