import { call, put, takeLatest} from 'redux-saga/effects'
import {getListRent, getListRentSuccess, getRank, getRankSuccess} from './slice'
import {setLoading} from '../../../../../redux/loadingSlice'
import { getRentAPI, getRankAPI } from 'app/services/carFavoriteService'

export function* carFavoriteSaga() {
  //list
  yield takeLatest(getListRent.type, function* eventRequested(action: any) {
    const { data } = yield call(getRentAPI, action.payload);
    yield put(getListRentSuccess(data));
  })

  // get rank list
  yield takeLatest(getRank.type, function* eventRequested(action: any) {
    const { data } = yield call(getRankAPI, action.payload);

    yield put(getRankSuccess(data.data));
  })
}