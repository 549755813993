import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IResponsesCommon, IResponsesDetailCommon} from 'app/types/commonTypes'
import {IUser} from '../../users/redux/slice'

export interface ContainerDealerState {
  dealers: IResponsesCommon<IUser>,
  dealer: IResponsesDetailCommon<IUser>
}

export const initialState: ContainerDealerState = {
  dealers: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as IUser[],
    },
  } as IResponsesCommon<IUser>,
  dealer: {
    data: {} as IUser
  } as IResponsesDetailCommon<IUser>
}

const dealerSlice = createSlice({
  name: 'user-state',
  initialState,
  reducers: {
    //list
    getListDealers(state, action: PayloadAction<any>) {},
    getListDealersSuccess: (state, action: PayloadAction<IResponsesCommon<IUser>>) => {
      state.dealers = action.payload
    },

    //detail
    getDealerDetail(state, action: PayloadAction<string>) {},
    getDealerDetailSuccess: (state, action: PayloadAction<IResponsesDetailCommon<IUser>>) => {
      state.dealer = action.payload
    }
  },
})

export const { getListDealers, getListDealersSuccess, getDealerDetail, getDealerDetailSuccess } = dealerSlice.actions

export default dealerSlice.reducer
