import {Action} from '@reduxjs/toolkit'
import {UserModel} from '../models/UserModel'
import {put, takeLatest, select, call} from 'redux-saga/effects'
import {getUserByToken, logoutAPI} from './AuthCRUD'
import moment from 'moment'
import { SESSION_EXPIRED } from '../../../Consts'
import {setLoading} from '../../../redux/loadingSlice'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  SetSessionExpired: '[Set Session Expired] Action',
  SetAccessToken: '[Set Access Token] Action',
  ForceLogout: '[Force Logout] Action'
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  session_expired: undefined,
  refreshToken: undefined
}

export interface IAuthState {
  user?: any
  accessToken?: string,
  session_expired?: string
  refreshToken?: string
}

export const reducer = (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
  switch (action.type) {
    case actionTypes.Login: {
      const accessToken = action.payload?.accessToken
      const user = action.payload?.user
      const refreshToken = action.payload?.refreshToken
      return {...state, accessToken, user, refreshToken}
    }

    case actionTypes.Register: {
      const accessToken = action.payload?.accessToken
      return {accessToken, user: undefined}
    }

    case actionTypes.UserRequested: {
      return {...state, user: undefined}
    }

    case actionTypes.UserLoaded: {
      const user = action.payload?.user
      return {...state, user}
    }

    case actionTypes.SetUser: {
      const user = action.payload?.user
      return {...state, user}
    }

    case actionTypes.SetSessionExpired: {
      const session_expired = action.payload?.session_expired
      return {...state, session_expired}
    }

    case actionTypes.SetAccessToken: {
      const accessToken = action.payload?.accessToken
      return {...state, accessToken}
    }

    case actionTypes.ForceLogout: {
      return initialAuthState
    }

    default:
      return state
  }
}


export const actions = {
  login: (accessToken: string, user: object, refreshToken: string) => ({type: actionTypes.Login, payload: {accessToken, user, refreshToken}}),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: {accessToken},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: object) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
  setSessionExpired: (session_expired: any) => ({type: actionTypes.SetSessionExpired, payload: {session_expired} }),
  setAccessToken: (accessToken: string) => ({type: actionTypes.SetAccessToken, payload: { accessToken }}),
  forceLogout: () => ({ type: actionTypes.ForceLogout })
}

const authSelector = (state: any) => state.auth

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.SetAccessToken, function *setSessionExpired() {
    const newSessionExpired = moment().add(SESSION_EXPIRED, 'minutes')
    yield put(actions.setSessionExpired(newSessionExpired))
    yield put(setLoading(false))
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const {data} = yield getUserByToken()
    const {data: user} = data
    yield put(actions.fulfillUser(user))
    yield put(setLoading(false))
  })

  yield takeLatest(actionTypes.Logout, function* logoutRequested() {
    const { data } = yield call(logoutAPI)
    yield put(actions.forceLogout())
    yield call([localStorage, localStorage.clear]);
  })
}