/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {get as _get} from 'lodash'
import { RootState } from 'setup/redux/Store'
import { ASSISTANT_ROLE, DEALER_ROLE } from 'app/contants'
import {shallowEqual, useSelector} from 'react-redux'

export function AsideMenuMain() {
  const intl = useIntl()
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const ROLE = _get(isAuthorized, 'roles[0].id', -1);
  const onlyAssistant = ROLE === ASSISTANT_ROLE;
  const onlyDealer = ROLE === DEALER_ROLE;
  
  return (
    <>
      <AsideMenuItem to='/dashboard' title='대시보드' />

      <AsideMenuItemWithSub to='/query/' title='조회' menuParent >
          <AsideMenuItem to='/query/database' title='DB 조회'/>
          <AsideMenuItem to='/query/notifications' title='공지사항 조회'/>
        </AsideMenuItemWithSub>
      {!onlyDealer && !onlyAssistant && (
        <AsideMenuItemWithSub to='/manager' title='관리' menuParent main>
          <AsideMenuItemWithSub to='/manager/quote' title='견적 관리'>
            {!onlyAssistant && (
              <AsideMenuItem to='/manager/quote/databases' title='DB 관리'/>
            )}
            <AsideMenuItem to='/manager/quote/exports' title='즉시출고 관리'/>
            <AsideMenuItem to='/manager/quote/compare-quotes' title='견적비교 관리'/>
          </AsideMenuItemWithSub>

          <AsideMenuItem to='/manager/users' title='회원 관리' />

          <AsideMenuItemWithSub to='/manager/community' title='커뮤니티 관리'>
            <AsideMenuItem to='/manager/community/posts' title='게시글 관리'/>
            <AsideMenuItem to='/manager/community/reports' title='신고 관리'/>
          </AsideMenuItemWithSub>

          <AsideMenuItemWithSub to='/manager/events' title='이벤트 관리'>
            <AsideMenuItem to='/manager/events/events' title='이벤트 관리'/>
            <AsideMenuItem to='/manager/events/event-point' title='포인트 관리'/>
            <AsideMenuItem to='/manager/events/event-coupon' title='쿠폰 관리'/>
          </AsideMenuItemWithSub>

          <AsideMenuItem to='/manager/alarm' title='알림 관리' />

          <AsideMenuItem to='/manager/notice' title='공지사항 관리' />

          <AsideMenuItemWithSub to='/manager/other' title='기타 관리'>
            <AsideMenuItem to='/manager/other/favorite-car' title='인기차량 관리'/>
            <AsideMenuItem to='/manager/other/youtube' title='유튜브 관리'/>
            <AsideMenuItem to='/manager/other/faq' title='FAQ 관리'/>
          </AsideMenuItemWithSub>

        </AsideMenuItemWithSub>

      )}
    </>
  )
}