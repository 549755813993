import React from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import {ROUTES} from 'app/routing/PrivateRoutes'
import {get as _get} from 'lodash'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from 'setup/redux/Store'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  menuParent?: boolean
  main?: boolean
}

const AsideMenuItemWithSub: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  menuParent,
  main = false
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config

  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const ROLE = _get(isAuthorized, 'roles[0].id', -1)
  const hasAsideMenu = ROUTES.find((route) => route.path.indexOf(to) > -1)?.roles.includes(ROLE)
  
  return (
    <>
      {(hasAsideMenu || main) && (
        <div
          className={clsx('menu-item', {'here show': isActive}, 'menu-accordion', {
            'aside-menu-item-with-sub': menuParent,
          })}
          data-kt-menu-trigger='click'
        >
          <span className='menu-link'>
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}
            {icon && aside.menuIcon === 'svg' && (
              <span className='menu-icon'>
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
            )}
            {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
            <span className='menu-title font-weight-500'>{title}</span>
            <span className='menu-arrow'></span>
          </span>
          <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': isActive})}>
            {children}
          </div>
        </div>
      )}
    </>
  )
}

export {AsideMenuItemWithSub}
