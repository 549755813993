import { IResponsesCommon, IResponsesUpdateCommon } from 'app/types/commonTypes';
import axios, { AxiosResponse } from 'axios'
import {ICar} from '../modules/management/car/redux/slice'

const CAR_URL = `cars`

export const getCarAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<ICar>>> => {
  let requestURL = `${CAR_URL}?sortedBy=desc&orderBy=created_at`;
  return axios.get(requestURL, {
    params
  })
};

export const getDetailCar = (uuid: string, params: any): Promise<AxiosResponse<IResponsesCommon<ICar>>> => {
  let requestURL = `${CAR_URL}/${uuid}`;
  
  return axios.get(requestURL, {
    params
  });
};

export const removeCarAPI = (uuids: any): Promise<AxiosResponse<IResponsesCommon<ICar>>> => {
  let requestURL = `${CAR_URL}/delete-multiple`;
  return axios.post(requestURL, {uuid: uuids});
};

export const createCarApi = (data: any): Promise<AxiosResponse<IResponsesUpdateCommon<ICar>>> => {
  return axios.post(CAR_URL, data)
};


export const updateCarApi = (id: string, data: any): Promise<AxiosResponse<IResponsesUpdateCommon<ICar>>> => {
  let requestURL = `${CAR_URL}/${id}`;
  
  return axios.put(requestURL, data)
};