import {createSlice, current, PayloadAction} from '@reduxjs/toolkit'
import { IResponsesCommon } from 'app/types/commonTypes'

export interface IFAQ {
  uuid: string,
  question: string,
  answer: string,
  order: number,
  status: number,
  created_at: string,
  updated_at: string,
}
export interface ContainerFAQState {
  faq: IResponsesCommon<IFAQ>
}

export const initialState: ContainerFAQState = {
  faq: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as IFAQ[]
    }
  } as IResponsesCommon<IFAQ>
}

const faqSlice = createSlice({
  name: 'faq-state',
  initialState,
  reducers: {
    getQuestionAnswer(state, action: PayloadAction<any>) {},
    getQuestionAnswerSuccess: (state, action: PayloadAction<IResponsesCommon<IFAQ>>) => {
      state.faq = action.payload
    },
    updateQuestionAnswer: (state, action: PayloadAction<IFAQ>) => {      
      const tempState = [...current(state).faq.data.data];
      const tempIndex = tempState.findIndex((el) => el.uuid === action.payload.uuid)      
      if(tempIndex >= 0) {
        state.faq.data.data[tempIndex] = action.payload
      }
    }
  }
})

export const { getQuestionAnswer, getQuestionAnswerSuccess, updateQuestionAnswer } = faqSlice.actions

export default faqSlice.reducer
