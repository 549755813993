/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {CommunityStatus} from './components/CommunityStatus'
import {DBAssistant} from './components/DBAssistant'
import {DBTrend} from './components/DBTrend'
import {RealTimeDBStatus} from './components/RealTimeDBStatus'

const DashboardPage: FC = () => (
  <>
    <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-12'>
        <CommunityStatus className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div>
    <div className='row gy-5 g-xl-8'>
      <div className='col-xl-6'>
        <DBTrend className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-6'>
        <RealTimeDBStatus className='card-xl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-12'>
        <DBAssistant className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div>
  </>
)

const DashboardWrapper: FC = () => {
  // const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>대시보드</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
