export const defaultFillter = {
  page: 1,
  limit: 20
}

export const statusSupply = [ // trạng thái cấp
  {
    value: 1,
    label: '구매완료' //đã mua
  },
  {
    value: 2,
    label: '사용완료' //đã sử dụng
  },
  {
    value: 3,
    label: '만료' // đã hết hạn
  }
]

export const typeSupply = [ // hình thức cấp\
  {
    value: 1,
    label: '관리자 지급' //admin cấp
  },
  {
    value: 2,
    label: '포인트 구매' //mua point
  }
]

export const sortPosts = [
  {
    value: 'created_at',
    label: '업로드 순'
  },
  {
    value: 'reactions_count',
    label: '좋아요 많은 순'
  },
  {
    value: 'comments_count',
    label: '댓글 많은 순'
  }
]

export const FORMAT_DATE = 'Y-m-d'
export const FORMAT_DATE_TIME = 'Y-m-d H:i'

export const userStatus = [
  {
    id: 1,
    text: '가입'
  },
  {
    id: 3,
    text: '탈퇴'
  }
]

export const joinType = [
  {
    id: 'normal',
    text: '일반회원'
  },
  {
    id: 'apple',
    text: 'Apple'
  },
  {
    id: 'naver',
    text: '네이버'
  },
  {
    id: 'kakao',
    text: '카카오'
  },
]

export const USER_NORMAL_ROLE = 4
export const ASSISTANT_ROLE = 2
export const DEALER_ROLE = 3
export const SUPPER_ADMIN = 1

export const FORMAT_DATE_MOMENT = 'yyyy-MM-DD'
export const FORMAT_DATE_TIME_MOMENT = 'YYYY-MM-DD HH:mm'
export const USER_VERIFIED_STATUS = 1

export const USER_LEAVE_STATUS = 3
export const USER_JOINED_STATUS = 1

export const userStatusSelect = [
  {
    value: USER_JOINED_STATUS,
    label: '가입'
  },
  {
    value: USER_LEAVE_STATUS,
    label: '탈퇴'
  }
]

export const assistantStatusTypes = [
  {
    value: USER_JOINED_STATUS,
    label: '재직'
  },
  {
    value: USER_LEAVE_STATUS,
    label: '퇴직'
  }
]

export const POINT_PAYMENTS_STATUS = 1
export const POINT_EXPIRATION_STATUS = 2
export const POINT_DEDUCTION_STATUS = 3


export const pointStatus = [
  {
    value: POINT_PAYMENTS_STATUS,
    label: '지급',
    classes: '',
  },
  {
    value: POINT_EXPIRATION_STATUS,
    label: '만료',
    classes: 'text-color-gray'
  },
  {
    value: POINT_DEDUCTION_STATUS,
    label: '차감',
    classes: 'text-color-red'
  }
]

export const DEFAULT_OPTION = {
  value: '',
  label: '전체선택'
}

export const EVENT_PROCESSING_STATUS = 1
export const EVENT_COMPLETE_STATUS = 3

export const EVENT_STATUS_OPTIONS = [
  {
    label: '진행중',
    value: EVENT_PROCESSING_STATUS,
  },
  {
    label: '완료',
    value: EVENT_COMPLETE_STATUS
  }
]

export const PROCESSING_STATUS = [
  {
    label: '처리예정',
    value: 1
  },
  {
    label: '처리완료',
    value: 2
  }
]

export const CONTRACT_IMMEDIATELY = 1
export const CONTRACT_WITHIN_ONE_MONTH = 2
export const CONTRACT_WITHIN_THREE_MONTHS = 3

export const CONTRACT_PERIOD_TYPE = [
  {
    label: '즉시',
    value: CONTRACT_IMMEDIATELY
  },
  {
    label: '1달 이내',
    value: CONTRACT_WITHIN_ONE_MONTH
  },
  {
    label: '3달 이내',
    value: CONTRACT_WITHIN_THREE_MONTHS
  }
]
