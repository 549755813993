import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IResponsesCommon, IResponsesDetailCommon} from 'app/types/commonTypes'
import {IUser} from '../../users/redux/slice'

export interface ContainerAssistantState {
  assistants: IResponsesCommon<IUser>,
  assistant: IResponsesDetailCommon<IUser>
}

export const initialState: ContainerAssistantState = {
  assistants: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as IUser[],
    },
  } as IResponsesCommon<IUser>,
  assistant: {
    data: {} as IUser
  } as IResponsesDetailCommon<IUser>
}

const assistantSlice = createSlice({
  name: 'user-state',
  initialState,
  reducers: {
    //list
    getListAssistants(state, action: PayloadAction<any>) {},
    getListAssistantsSuccess: (state, action: PayloadAction<IResponsesCommon<IUser>>) => {
      state.assistants = action.payload
    },

    //detail
    getAssistantDetail(state, action: PayloadAction<string>) {},
    getAssistantDetailSuccess: (state, action: PayloadAction<IResponsesDetailCommon<IUser>>) => {
      state.assistant = action.payload
    }
  },
})

export const { getListAssistants, getListAssistantsSuccess, getAssistantDetail, getAssistantDetailSuccess } = assistantSlice.actions

export default assistantSlice.reducer
