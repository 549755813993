/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {AsideMenu} from './AsideMenu'
import { useHistory } from 'react-router'

const AsideDefault: FC = () => {
  const {config, classes} = useLayout()
  const history = useHistory()
  const {aside} = config

  const onRedirectHomePage = () => {
    history.push('/')
  }
  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      {/* begin::Aside menu */}
      <div className='aside-menu logo-layout d-flex justify-content-center cursor-pointer' onClick={onRedirectHomePage}>
        <img src='/images/chaket_logo.png' height="auto" width="80%"/>
      </div>
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
    </div>
  )
}

export {AsideDefault}
