import {PayloadAction} from '@reduxjs/toolkit'
import {showToastr} from 'app/helpers/toastr'
import { getAlarmAPI, getMessagesAlarmAPI } from 'app/services/alarmService'
import { IResponsesErrorCommon } from 'app/types/commonTypes'
import {call, put, takeLatest} from 'redux-saga/effects'
import {
  getAlarms, getAlarmsSuccess, getMessagesAlarms, getMessagesAlarmsSuccess
} from './slice'

export function* alarmSaga() {
  yield takeLatest(
    getAlarms.type,
    function* alarmsRequested(action: PayloadAction<{ params: any, type: 1 | 2 | 3}>) {
      try {
        const { data } = yield call(getAlarmAPI, action.payload.params, action.payload.type);    
        yield put(getAlarmsSuccess(data));
      } catch (error: unknown) {
        const err = error as IResponsesErrorCommon<any>
        yield showToastr(err.data.meta.message, 'fail')
      }
    }
  )
  yield takeLatest(
    getMessagesAlarms.type,
    function* alarmsRequested(action: PayloadAction<string>) {
      try {
        const { data } = yield call(getMessagesAlarmAPI, action.payload);    
        yield put(getMessagesAlarmsSuccess(data.data.data));
      } catch (error: unknown) {  
        const err = error as IResponsesErrorCommon<any>
        yield showToastr(err.data.meta.message, 'fail')
      }
    }
  )
}
