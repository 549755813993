import axios, {AxiosResponse} from 'axios'

const RENLEASE_URL = `rents`

export const getQuotesInstallmentCashAPI = (params: any): any => {
  return axios.get(RENLEASE_URL, {
    params: {
      ...params,
      orderBy: 'created_at',
      sortedBy: 'desc',
      with: 'manufacturer;carType;carLevel;engineType;user;options;insideColor;outsideColor;attachments;assistant;dealer',
      types: 5
    },
  })
}

export const createQuotesInstallmentCashAPI = (data: any): any => {
  return axios.post(RENLEASE_URL, {
    ...data,
    type: 5
  })
}

