import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IResponsesCommon } from 'app/types/commonTypes'

export interface ICarFavorite {
  id: number,
  uuid: string,
  status: number,
  type: string,
  manufacturer_id: string|number,
  car_type_id: string|number,
  car_level: any,
  manufacturer:any,
  total_price: string|number,
  pay_month_from: string|number,
  pay_month_to: string|number,
  favorite_age: string|number,
  fuel_capacity_from: string,
  fuel_capacity_to: string,
  num_export: string|number,
  price_from: string|number,
  price_to: string|number,
  content: string,
  created_at: string,
  updated_at: string
}
export interface IRank {
  manufacturer_id: number|string,
  manufacturer: any
}

export interface ContainerFavoriteCarState {
  rents: IResponsesCommon<ICarFavorite>
  rentDetail: IResponsesCommon<ICarFavorite>
  rank: IRank[]
}

export const initialState: ContainerFavoriteCarState = {
  rents: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as ICarFavorite[]
    }
  } as IResponsesCommon<ICarFavorite>,

  rentDetail: {
    data: {
      data: {} as ICarFavorite[]
    }
  } as IResponsesCommon<ICarFavorite>,

  rank: []
}

const rentSlice = createSlice({
  name: 'rent-state',
  initialState,
  reducers: {
    //list
    getListRent(state, action: PayloadAction<any>) { },
    getListRentSuccess: (state, action: PayloadAction<IResponsesCommon<ICarFavorite>>) => {
      state.rents = action.payload
    },

    //rank
    getRank(state, action: PayloadAction<any>) { },
    getRankSuccess: (state, action: PayloadAction<IRank[]>) => {
      state.rank = action.payload
    },
  }
})

export const { getListRent, getListRentSuccess, getRank, getRankSuccess } = rentSlice.actions

export default rentSlice.reducer
