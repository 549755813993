import React, {useEffect, useState} from 'react'
import {ApexOptions} from 'apexcharts'
import Chart from 'react-apexcharts'
import {useAppDispatch, useAppSelector} from 'app/hooks/redux'
import {getInforStateDashboard} from '../redux/slice'
import {TYPES_FILTER_DASHBOARD} from 'app/contants/dashboard'
import moment from 'moment'
import {FORMAT_DATE_MOMENT} from 'app/contants'
import {selectLegendsState, selectSeriesState} from '../redux/selector'


type Props = {
  className: string
}

const CURRENT_TIME = moment().format(FORMAT_DATE_MOMENT)

const DBTrend: React.FC<Props> = ({className}) => {
  const [type, setType] = useState<string>(TYPES_FILTER_DASHBOARD.DATE)
  const dispatch = useAppDispatch()
  const series = useAppSelector(selectSeriesState)
  
  const legends = useAppSelector(selectLegendsState)
  const PREV_TIME = moment()
  .subtract(10, type === TYPES_FILTER_DASHBOARD.DATE ? 'days' : 'months')
  .format(FORMAT_DATE_MOMENT)

  const options = {
    chart: {
      id: 'basic-bar',
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        bottom: -10,
        left: 50,
        right: 50,
      },
    },
    colors: ['#22AC4C', '#7A2EEE'],
    xaxis: {
      categories: legends,
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '17px',
          colors: '#373737',
        },
      },
      axisBorder: {
        show: true,
        color: '#9B9B9B',
        height: 2,
        width: '100%',
        offsetX: -38,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '17px',
          colors: '#373737',
        },
        formatter: function (val, index) {
          const label = val === 0 ? '' : val
          return `${label}`
        },
      },
      axisBorder: {
        show: true,
        color: '#9B9B9B',
        width: 2,
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      offsetY: 10,
    },
    stroke: {
      width: 2,
    },
    markers: {
      size: 5,
      colors: undefined,
      strokeColors: '#fff',
      strokeWidth: 0,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      hover: {
        size: 8,
        sizeOffset: 3,
      },
    },
  } as ApexOptions
  
  useEffect(() => {
    dispatch(getInforStateDashboard({filter_by: type, from: PREV_TIME, to: CURRENT_TIME}))
  }, [PREV_TIME, dispatch, type])

  return (
    <div className={`card box-shadow-primary ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-1 border-bottom border-gray-680'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fs-3'>DB 추이</span>
        </h3>
        <div className='card-toolbar'>
          <select
            name='status'
            data-control='select2'
            data-hide-search='true'
            className='form-select form-select-white form-select-sm fs-3 w-fit-content border-0 bg-transparent'
            defaultValue={TYPES_FILTER_DASHBOARD.DATE}
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option value={TYPES_FILTER_DASHBOARD.DATE}>일별보기</option>
            <option value={TYPES_FILTER_DASHBOARD.MONTH}>월별보기</option>
          </select>
        </div>
      </div>
      {/* end::Header */}
      { /*console.table({options})*/ }
      { /*console.table({series})*/ }
      
      <div className='card-body py-0 px-1 dashboard-table-wrapper' style={{overflow: 'hidden'}}>
      {
      /*
        <Chart
          options={{...options}}
          series={series}
          type='line'
          width='100%'
          height='230'
        />
      */
      }
      </div>
      
      
    </div>
  )
}

export {DBTrend}
