import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { formaterOptions } from 'app/helpers/formatter'
import {showToastr} from 'app/helpers/toastr'
import {
  getAssistantsAPI,
  getCarColorsAPI,
  getCarColorsByEngineTypesAPI,
  getCarLevelsAPI,
  getCarOptionssAPI,
  getCarTypesAPI, getDealersAPI,
  getEngineTypesAPI, getFinancialCompanyAPI, getManufacturersAPI,
  getUsersAPI,
} from 'app/services/optionsService'
import {ResponseGenerator} from 'app/types/commonTypes'
import {call, put, takeLatest, all} from 'redux-saga/effects'
import {setOptionsLoading} from './loadingSlice'
import { Option } from 'app/components/common/MultiSelects'
import {getNotificationCounterApi} from '../services/notificationCounterService'
import {setNotificationCounter} from './notificationCounterSlice'
const DEFAULT_PARAM_OPTION = {
  page: 1,
  limit: 8500,
}
type OptionsTypes = {
  label: string
  value: string | number
}

export interface ContainerOptionsState {
  isForceHiddenLoading: boolean
  manufacturerTypes: OptionsTypes[]
  carTypes: OptionsTypes[]
  engineTypes: OptionsTypes[]
  carLevels: OptionsTypes[]
  users: OptionsTypes[]
  hasExisted: boolean,
  assistants: OptionsTypes[],
  dealers: OptionsTypes[],
  financialCompany: OptionsTypes[],
  carColors: any[],
  carColorsEngine: any[],
  carOptions: Option[],
  energyTypes: Option[],
}

const initialLoadingState: ContainerOptionsState = {
  isForceHiddenLoading: false,
  manufacturerTypes: [],
  carTypes: [],
  engineTypes: [],
  carLevels: [],
  users: [],
  hasExisted: false,
  assistants: [],
  dealers: [],
  financialCompany: [],
  carColors: [],
  carColorsEngine: [],
  carOptions: [],
  energyTypes: [],
}
const optionsCommonSlice = createSlice({
  name: 'options-common',
  initialState: initialLoadingState,
  reducers: {
    getOptionsRenstLease() {},
    getManufacturerSuccess: (state, action: PayloadAction<OptionsTypes[]>) => {
      state.manufacturerTypes = action.payload
    },
    getCarType() {},
    getCarTypeSuccess: (state, action: PayloadAction<OptionsTypes[]>) => {
      state.carTypes = action.payload
    },
    getEngineSuccess: (state, action: PayloadAction<OptionsTypes[]>) => {
      state.engineTypes = action.payload
    },
    getEnergySuccess: (state, action: PayloadAction<OptionsTypes[]>) => {
      state.energyTypes = action.payload
    },
    getCarLevelsSuccess: (state, action: PayloadAction<OptionsTypes[]>) => {
      state.carLevels = action.payload
    },
    getUserSuccess: (state, action: PayloadAction<OptionsTypes[]>) => {
      state.users = action.payload
    },
    setHasExisted: (state, action: PayloadAction<boolean>) => {
      state.hasExisted = action.payload
    },
    setForceHiddenLoading: (state, action: PayloadAction<boolean>) => {
      state.isForceHiddenLoading = action.payload
    },
    // Get Assistant
    getAssistantOtp: () => {},
    getAssistantSuccess: (state, action: PayloadAction<OptionsTypes[]>) => {
      state.assistants = action.payload
    },

    // Get Dealer
    getDealerOtp: () => {},
    getDealerSuccess: (state, action: PayloadAction<OptionsTypes[]>) => {
      state.dealers = action.payload
    },
    // Get Financial Company
    getFinancialCompany: (state, action: PayloadAction<OptionsTypes[]>) => {
      state.financialCompany = action.payload
    },
    getCarColorByEngineId: (state, action: PayloadAction<string| number>) => {},
    // Get Car color
    getCarColorByEngineIdSuccess: (state, action: PayloadAction<OptionsTypes[]>) => {
      state.carColorsEngine = action.payload
    },
    getCarColorSuccess: (state, action: PayloadAction<OptionsTypes[]>) => {
      state.carColors = action.payload
    },
    getCarOption: (state, action: PayloadAction<string| number>) => {},
    getCarOptionsSuccess: (state, action: PayloadAction<OptionsTypes[]>) => {
      state.carOptions = action.payload
    },
    setDefaultOptions: (state) => {
      state.carOptions = []
      state.carColorsEngine = []
    }
  },
})

export const {
  getOptionsRenstLease,
  getCarType,
  getManufacturerSuccess,
  getCarTypeSuccess,
  getEngineSuccess,
  getCarLevelsSuccess,
  getUserSuccess,
  setHasExisted,
  getAssistantSuccess,
  getDealerSuccess,
  getFinancialCompany,
  setForceHiddenLoading,
  getAssistantOtp,
  getDealerOtp,
  getCarColorSuccess,
  getCarOptionsSuccess,
  getEnergySuccess,
  getCarOption,
  getCarColorByEngineId,
  getCarColorByEngineIdSuccess,
  setDefaultOptions,
} = optionsCommonSlice.actions

export default optionsCommonSlice.reducer

export function* optionsStateSaga() {
  yield takeLatest(getOptionsRenstLease.type, function* optionsRequested(action: any) {
    yield put(setOptionsLoading(true))
    try {
      const [
        responCarType,
        responEngine,
        responCarLevels,
        responUsers,
        responManufacturer,
        responAssistant,
        responDealer,
        responFinancialCompany,
        responCarColor,
        // responCarOptions,
        responseNotificationCounter
      ] = yield all([
        call(getCarTypesAPI, DEFAULT_PARAM_OPTION),
        call(getEngineTypesAPI, { all: 1 }),
        call(getCarLevelsAPI, DEFAULT_PARAM_OPTION),
        call(getUsersAPI, DEFAULT_PARAM_OPTION),
        call(getManufacturersAPI, DEFAULT_PARAM_OPTION),
        call(getAssistantsAPI, DEFAULT_PARAM_OPTION),
        call(getDealersAPI, DEFAULT_PARAM_OPTION),
        call(getFinancialCompanyAPI, DEFAULT_PARAM_OPTION),
        call(getCarColorsAPI, DEFAULT_PARAM_OPTION),
        // call(getCarOptionssAPI, { page: 1, limit: 3500 }),
        call(getNotificationCounterApi),
      ])
      yield put(setHasExisted(true))
      yield put(getManufacturerSuccess(formaterOptions(responManufacturer.data.data)))
      yield put(getCarTypeSuccess(formaterOptions(responCarType.data.data)))
      yield put(getEngineSuccess(formaterOptions(responEngine.data)))
      yield put(getEnergySuccess(formaterOptions(responEngine.data, 'energy')))
      yield put(getCarLevelsSuccess(formaterOptions((responCarLevels.data.data || []).map((x:any) => ({...x, manufacturer_id: x?.car_type?.manufacturer_id})))))
      yield put(getUserSuccess(formaterOptions(responUsers.data.data, 'username')))
      yield put(getAssistantSuccess(formaterOptions(responAssistant.data.data)))
      yield put(getDealerSuccess(formaterOptions(responDealer.data.data)))
      yield put(getFinancialCompany(formaterOptions(responFinancialCompany.data.data)))
      yield put(getCarColorSuccess(formaterOptions(responCarColor.data.data)))
      // yield put(getCarOptionsSuccess(formaterOptions(responCarOptions.data.data)))
      yield put(setNotificationCounter(responseNotificationCounter.data.count))
      yield put(setOptionsLoading(false))
    } catch (error: unknown) {
      yield put(setHasExisted(false))
      yield put(setOptionsLoading(false))
      yield showToastr(error as string, 'fail')
    }
  })

  yield takeLatest(getCarType.type, function* optionsRequested(action: any) {
    try {
      const responCarType: ResponseGenerator = yield call(getCarTypesAPI, DEFAULT_PARAM_OPTION)
      yield put(getCarTypeSuccess(formaterOptions(responCarType.data.data.data)))
    } catch (error: unknown) {
      yield showToastr(error as string, 'fail')
    }
  })

  yield takeLatest(getDealerOtp.type, function* dealerRequested(action: any) {
    try {
      yield put(setOptionsLoading(true))
      const responDealer: ResponseGenerator = yield call(getDealersAPI, DEFAULT_PARAM_OPTION)
      yield put(getDealerSuccess(formaterOptions(responDealer.data.data)))
      yield put(setOptionsLoading(false))
    } catch (error: unknown) {}
  })

  yield takeLatest(getAssistantOtp.type, function* assistantRequested(action: any) {
    try {
      yield put(setOptionsLoading(true))
      const responAssistant: ResponseGenerator = yield call(getAssistantsAPI, DEFAULT_PARAM_OPTION)
      yield put(getAssistantSuccess(formaterOptions(responAssistant.data.data)))
      yield put(setOptionsLoading(false))
    } catch (error: unknown) {}
  })

  yield takeLatest(getCarOption.type, function* assistantRequested(action: any) {
    try {
      yield put(setOptionsLoading(true))
      const respons: ResponseGenerator = yield call(getCarOptionssAPI, { all: 1, engine_type_id: action.payload})
      yield put(getCarOptionsSuccess(formaterOptions(respons.data)))
      yield put(setOptionsLoading(false))
    } catch (error: unknown) {}
  })

  yield takeLatest(getCarColorByEngineId.type, function* assistantRequested(action: any) {
    try {
      yield put(setOptionsLoading(true))
      const respons: ResponseGenerator = yield call(getCarColorsByEngineTypesAPI, action.payload) 
      yield put(getCarColorByEngineIdSuccess(formaterOptions(respons.data.data)))
      yield put(setOptionsLoading(false))
    } catch (error: unknown) {}
  })
}
