import React from 'react'
import FadeLoader from 'react-spinners/FadeLoader'
import '../../../_metronic/assets/sass/layout/loading.scss'
type Props = {
  loading: boolean,
}

const Loading: React.FC<Props> = ({loading}) => {
  return (
    <>
      {
        loading ? (
          <div className="loading-wrapper">
            <div className="loading-layer" />
            <div className="loading-content">
              <div className="row d-flex flex-column justify-content-center">
                <div className="col-md-12">
                  <div>
                    <FadeLoader loading={loading} color="#286DD1"/>
                  </div>
                </div>
                <div className="col-md-12 mt-20 mr-20">
                  <span className="pt-10 loading-title ">로딩중입니다.</span>
                </div>
              </div>
            </div>
          </div>
        ) : ''
      }
    </>
  )
}

export default Loading