import _ from 'lodash'
import moment from 'moment'
import find from 'lodash/find'
import { userStatus, joinType } from '../../contants'

const dateFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
  if(cell) return moment(cell).format('YYYY.MM.DD')
  else return '-'
}

const statusFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
  if(cell === 1) return '처리중'
  else return '처리완료'
}

const userStatusFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
  const status = find(userStatus, function (item) {
    return item.id === cell
  })
  if(status) return status.text
  else return '-'
}

const joinTypeFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
  const join_type = find(joinType, function (item) {
    return item.id === cell
  })
  if(join_type) return join_type.text
  else return '-'
}

// remove empty query params
const removeEmptyObjects = (obj: object) : object  => {
  return _.omitBy(obj, (v) =>_.isUndefined(v) || _.isNull(v) || v === '' || v === 'all')
}

// format option
const formaterOptions = (options: any, key?: string) : { label: string, value: string | number }[]  => {
  return options.map((o: any) => ({ label: key ? o[key] : o.name, value: o.id, ...o }))
}

export {
  dateFormatter,
  statusFormatter,
  removeEmptyObjects,
  userStatusFormatter,
  joinTypeFormatter,
  formaterOptions
}