import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IResponsesCommon} from '../../../../../../types/commonTypes'

export interface IQuotesDBRenLease {
  installmentCash: IResponsesCommon<any>
}

const initialState: IQuotesDBRenLease = {
  installmentCash: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as any
    }
  } as IResponsesCommon<any>
}

const quoteInstallmenthCashSlice = createSlice({
  name: 'quotes-db-installment-cash',
  initialState,
  reducers: {
    getListQuotesInstallmentCash(state, action: PayloadAction<any>) {},
    getListQuoteInstallmentCashSuccess: (state, action: PayloadAction<IResponsesCommon<any>>) => {
      state.installmentCash = action.payload
    }
  }
})

export const { getListQuotesInstallmentCash, getListQuoteInstallmentCashSuccess } = quoteInstallmenthCashSlice.actions

export default quoteInstallmenthCashSlice.reducer