import {INotification} from 'app/modules/query/notification/redux/slice'
import {IResponsesCommon, IResponsesUpdateCommon} from 'app/types/commonTypes'
import axios, {AxiosResponse} from 'axios'

const NOTIFICATION_URL = `notifications`

export const getNotificationAPI = (
  params: any
): Promise<AxiosResponse<IResponsesCommon<INotification>>> => {
  return axios.get(NOTIFICATION_URL, {
    params: {
      ...params,
      orderBy: params.type === 2 ? 'created_at' : 'is_fixed;created_at',
      sortedBy: 'desc'
    },
  })
}

export const getNotificationDetailAPI = (id: string): Promise<AxiosResponse<INotification>> => {
  return axios.get(`${NOTIFICATION_URL}/${id}`, {})
}

export const createNotificationAPI = (
  data: any
): Promise<AxiosResponse<IResponsesUpdateCommon<INotification>>> => {
  return axios.post(NOTIFICATION_URL, data)
}

export const updateNotificationAPI = (
  id: string,
  data: any
): Promise<AxiosResponse<IResponsesUpdateCommon<INotification>>> => {
  let requestURL = `${NOTIFICATION_URL}/${id}`
  return axios.put(requestURL, data)
}

export const deleteNotificationAPI = (
  data: string[]
): Promise<AxiosResponse<IResponsesUpdateCommon<INotification>>> => {
  let requestURL = `${NOTIFICATION_URL}/delete-multiple`
  return axios.post(requestURL, {uuid: data})
}
