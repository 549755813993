import { IResponsesCommon, IResponsesUpdateCommon } from 'app/types/commonTypes';
import axios, { AxiosResponse } from 'axios'
import {ICarReview} from '../modules/management/car_review/redux/slice'

const CAR_REVIEW_URL = `car-reviews`

export const getCarReviewAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<ICarReview>>> => {
  let requestURL = `${CAR_REVIEW_URL}?sortedBy=desc&orderBy=created_at&withCount=reports`;
  return axios.get(requestURL, {
    params
  })
};

export const getDetailCarReviewApi = (uuid: string, params: any): Promise<AxiosResponse<IResponsesCommon<ICarReview>>> => {
  let requestURL = `${CAR_REVIEW_URL}/${uuid}`;
  
  return axios.get(requestURL, {
    params
  });
};

export const removeCarReviewAPI = (uuids: any): Promise<AxiosResponse<IResponsesCommon<ICarReview>>> => {
  let requestURL = `${CAR_REVIEW_URL}/delete-multiple`;
  return axios.post(requestURL, {uuid: uuids});
};

export const updateCarReviewAPI = (id: string, data: any): Promise<AxiosResponse<IResponsesUpdateCommon<ICarReview>>> => {
  let requestURL = `${CAR_REVIEW_URL}/${id}`;
  
  return axios.put(requestURL, data)
};