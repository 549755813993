import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IResponsesCommon} from 'app/types/commonTypes'

export interface IInstallmentCash {
  id: number
  uuid: string
  user_id: number
  status: number
  approval_rate: number
  type: number
  customer_type: number
  customer_name: string
  customer_phone: string
  origin: number
  category?: string
  manufacturer_id: number
  car_type_id: number
  engine_type_id: number
  car_level_id: number
  financial_company_id?: number
  inside_color: string
  outside_color: string
  pricing_list?: string[]
  total_price?: number
  contract_time: number
  distance: number
  pre_type: number
  pre_percent: number
  insurance_age: string
  is_insurance: boolean
  maintenance_type: number
  no_responsible_amount: number
  receive_address: string
  consultation_time: string
  created_at: string
  updated_at: string
  is_bad_debt: number
  consultation_completed_date: string
  contract_completed_date: string
  handover_date: string
  hope_contract_timing: string
  memo: string
}
export interface ContainerInstallmentCashState {
  installmentCash: IResponsesCommon<IInstallmentCash>
}

export const initialState: ContainerInstallmentCashState = {
  installmentCash: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as IInstallmentCash[],
    },
  } as IResponsesCommon<IInstallmentCash>,
}

const installmentCashSlice = createSlice({
  name: 'installment-cash-state',
  initialState,
  reducers: {
    getInstallmentCash(state, action: PayloadAction<any>) {},
    getInstallmentCashSuccess: (
      state,
      action: PayloadAction<IResponsesCommon<IInstallmentCash>>
    ) => {
      state.installmentCash = action.payload
    },
  },
})

export const {getInstallmentCash, getInstallmentCashSuccess} = installmentCashSlice.actions

export default installmentCashSlice.reducer
