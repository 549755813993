import clsx from 'clsx'
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {useLayout} from '../../../core/LayoutProvider'
import {usePageData} from '../../../core/PageData'

const DefaultTitle: FC = () => {
  const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()
  const {config, classes} = useLayout()
  return (
    <div
      id='kt_page_title'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
      className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
    >
      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs ? (
          <>
            <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
              {Array.from(pageBreadcrumbs).map((item, index) => (
                <li
                  className={clsx('breadcrumb-item')}
                  key={`${item.path}${index}`}
                >
                  <span>
                    {item.title}
                  </span>
                </li>
              ))}
              <li className='breadcrumb-item'>{pageTitle}</li>
            </ul>
          </>
        ) : (
        <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
          <li className='breadcrumb-item'>{pageTitle}</li>
        </ul>
      )}
    </div>
  )
}

export {DefaultTitle}
