import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IResponsesCommon} from 'app/types/commonTypes'

export interface IExport {
  uuid: string
  created_at: string
  updated_at: string
}
export interface ContainerExporttate {
  export: IResponsesCommon<IExport>
}

export const initialState: ContainerExporttate = {
  export: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as IExport[],
    },
  } as IResponsesCommon<IExport>,
}

const exportSlice = createSlice({
  name: 'export-state',
  initialState,
  reducers: {
    getExports(state, action: PayloadAction<any>) {},
    getExportsSuccess: (state, action: PayloadAction<IResponsesCommon<IExport>>) => {
      state.export = action.payload
    },
  },
})

export const {getExports, getExportsSuccess} = exportSlice.actions

export default exportSlice.reducer
