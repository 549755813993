import { IExport } from 'app/modules/management/quotes/export/redux/slice';
import { IResponsesCommon, IResponsesUpdateCommon } from 'app/types/commonTypes';
import axios, { AxiosResponse } from 'axios'

const EXPORT_URL = `exports`

export const getExportAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<IExport>>> => {
  return axios.get(EXPORT_URL, {
    params: {
      ...params,
      orderBy:'created_at',
      sortedBy: 'desc'
    },
  })
};

export const createExportAPI = (data: any): Promise<AxiosResponse<IResponsesUpdateCommon<IExport>>> => {
  return axios.post(EXPORT_URL, data)
};

export const updateExportAPI = (id: string, data: any): Promise<AxiosResponse<IResponsesUpdateCommon<IExport>>> => {
  let requestURL = `${EXPORT_URL}/${id}`;
  return axios.put(requestURL, data)
};

export const deleteExportAPI = (
  data: string[]
): Promise<AxiosResponse<IResponsesUpdateCommon<IExport>>> => {
  let requestURL = `${EXPORT_URL}/delete-multiple`
  return axios.post(requestURL, {uuid: data})
}