import {useEffect} from 'react'
import Echo from 'laravel-echo'

const WS_URL = process.env.REACT_APP_WS_URL
const HOST_SOKET = 'socket.io'

const useLavavelEcho = <T>(
  event: string,
  name = 'channel',
  handleTriggeringEvents: (data: T) => void
) => {
  let echo: any
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    echo = new Echo({
      broadcaster: HOST_SOKET,
      host: WS_URL,
    })
    echo.channel(name).listen(event, (data: T) => {
      handleTriggeringEvents(data)
    })
    return () => {
      echo.leaveChannel(name)
    }
  }, [])
}

export default useLavavelEcho
