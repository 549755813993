import { call, put, takeLatest} from 'redux-saga/effects'
import {getListManufacturer, getListManufacturerSuccess} from './slice'
import {setLoading} from 'app/redux/loadingSlice'
import { getManufacturerAPI } from 'app/services/manufacturerService'

export function* manufacturerSaga() {
  //list
  yield takeLatest(getListManufacturer.type, function* eventRequested(action: any) {
    const { data } = yield call(getManufacturerAPI, action.payload);
    yield put(getListManufacturerSuccess(data));
  })
}