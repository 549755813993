import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface ILoading {
  isLoading: boolean,
  isOptionsLoading: boolean,
}

const initialLoadingState: ILoading = {
  isLoading: false,
  isOptionsLoading: false,
}

const loadingSlice = createSlice({
  name: 'loading',
  initialState: initialLoadingState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setOptionsLoading: (state, action: PayloadAction<boolean>) => {
      state.isOptionsLoading = action.payload
    }
  }
})

export const { setLoading, setOptionsLoading } = loadingSlice.actions

export default loadingSlice.reducer
