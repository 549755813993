import { IFAQ } from 'app/modules/management/other/faq/redux/slice';
import { IResponsesCommon, IResponsesUpdateCommon } from 'app/types/commonTypes';
import axios, { AxiosResponse } from 'axios'

const QUESTION_ANSWERS_URL = `question-and-answers`

export const getQuestionAnswerAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<IFAQ>>> => {
  return axios.get(QUESTION_ANSWERS_URL, {
    params: {
      ...params,
      orderBy:'created_at',
      sortedBy: 'desc'
    },
  })
};

export const createQuestionAnswerAPI = (data: any): Promise<AxiosResponse<IResponsesUpdateCommon<IFAQ>>> => {
  return axios.post(QUESTION_ANSWERS_URL, data)
};

export const updateQuestionAnswerAPI = (id: string, data: any): Promise<AxiosResponse<IResponsesUpdateCommon<IFAQ>>> => {
  let requestURL = `${QUESTION_ANSWERS_URL}/${id}`;
  return axios.put(requestURL, data)
};

export const deleteQuestionAnswerAPI = (
  data: string[]
): Promise<AxiosResponse<IResponsesUpdateCommon<IFAQ>>> => {
  let requestURL = `${QUESTION_ANSWERS_URL}/delete-multiple`
  return axios.post(requestURL, {uuid: data})
}