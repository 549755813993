import { getPostAPI } from 'app/services/postService'
import { call, put, takeLatest} from 'redux-saga/effects'
import {setLoading} from '../../../../../redux/loadingSlice'
import { 
  getPostsList, 
  getPostsListSuccess
} from './slice'

export function* postSaga() {
  yield takeLatest(getPostsList.type, function* postRequested(action: any) {
    yield put(setLoading(true))
    
    const { data } = yield call(getPostAPI, action.payload);
    yield put(getPostsListSuccess(data));
    yield put(setLoading(false))
  })
}