import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {takeLatest, put, call} from 'redux-saga/effects'
import { getNotificationCounterApi } from '../services/notificationCounterService'

export interface INotification {
  counter: number
}

const initialState: INotification = {
  counter: 0
}

const notificationCounterSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotificationCounter: (state, action: PayloadAction<number>) => {
      state.counter = action.payload
    },
    getNotificationCounter() {},
  }
})

export const { setNotificationCounter, getNotificationCounter }  = notificationCounterSlice.actions

export default notificationCounterSlice.reducer
