import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IResponsesCommon } from 'app/types/commonTypes'

export interface IManufacturer {
  uuid: string,
  id: number,
  name: string,
  website: string,
  description: string,
  email: string,
  is_local: number,
  old_id: number,
  vehicle_type: number,
  created_at: string,
  image: object,
  status: number
}

export interface ContainerFAQState {
    manufacturers: IResponsesCommon<IManufacturer>
}

export const initialState: ContainerFAQState = {
    manufacturers: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as IManufacturer[]
    }
  } as IResponsesCommon<IManufacturer>
}

const manufacturerSlice = createSlice({
  name: 'manufacturer-state',
  initialState,
  reducers: {
    //list
    getListManufacturer(state, action: PayloadAction<any>) { },
    getListManufacturerSuccess: (state, action: PayloadAction<IResponsesCommon<IManufacturer>>) => {
      state.manufacturers = action.payload
    },
  }
})

export const { getListManufacturer, getListManufacturerSuccess } = manufacturerSlice.actions

export default manufacturerSlice.reducer
