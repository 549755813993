import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `auth/profile`
export const LOGIN_URL = `auth/login`
export const REGISTER_URL = `register`
export const REQUEST_PASSWORD_URL = `forgot_password`
export const REFRESH_TOKEN = 'auth/refresh-token'
export const LOGOUT = 'auth/logout'

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
    device_token: `${email}-${Math.round((new Date()).getTime() / 1000)}`,
    device_type: `admin`,
  })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.get<{result: boolean}>(REQUEST_PASSWORD_URL, {
    params: {
      email: email,
    },
  })
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get(GET_USER_BY_ACCESSTOKEN_URL, {
    params: {
      with: 'manufacturer'
    }
  })
}

export function refreshToken(refresh_token: any) {
  return axios.post(REFRESH_TOKEN, {
    refresh_token,
  })
}

export function logoutAPI() {
  return axios.post(LOGOUT)
}
