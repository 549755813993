import { call, put, takeLatest} from 'redux-saga/effects'
import {
  getListAssistants,
  getListAssistantsSuccess,
  getAssistantDetail,
  getAssistantDetailSuccess
} from './slice'
import {
  getUserAPI,
  getUserDetailAPI
} from 'app/services/userService'
import {ASSISTANT_ROLE} from '../../../../../contants'

export function* assistantSaga() {
  //list
  yield takeLatest(getListAssistants.type, function* usersRequested(action: any) {
    try {
      const { data } = yield call(getUserAPI, action.payload, ASSISTANT_ROLE);
      yield put(getListAssistantsSuccess(data));
    } catch (err) {}
  })

  yield takeLatest(getAssistantDetail.type, function* userDetailRequested(action: any) {
    try {
      const { data } = yield call(getUserDetailAPI, action.payload, '', 'assistant')
      yield put(getAssistantDetailSuccess(data))
    } catch (err) {}
  })
}