import { IResponsesCommon, IResponsesUpdateCommon } from 'app/types/commonTypes';
import axios, { AxiosResponse } from 'axios'
import {IEvent} from '../modules/management/events/event/redux/slice'

const EVENT_URL = `events`

export const getEventAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<IEvent>>> => {
  let requestURL = `${EVENT_URL}`;
  return axios.get(requestURL, {
    params: {
      ...params,
      orderBy: 'created_at',
      sortedBy: 'desc',
    }
  })
};

export const updateEventAPI = (uuid: string, description: string): Promise<AxiosResponse<IResponsesUpdateCommon<IEvent>>> => {
  const requestURL = `${EVENT_URL}/${uuid}`
  return axios.put(requestURL, {
    description
  })
}

export const deleteEventApi = (uuid: string[]): Promise<AxiosResponse<IResponsesUpdateCommon<IEvent>>> => {
  const requestUrl = `${EVENT_URL}/delete-multiple`
  return axios.post(requestUrl, {
    uuid
  })
}