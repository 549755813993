// format string file
const formaterFileName = (name: string) => {
  const splitFile = name.split(`\\`);
    const nameFile = splitFile[splitFile.length - 1];
    return nameFile
}

const compactCharter = (
  char: string,
  countStart = 20,
  countEnd = 4,
) => {
  if (char.split(' ').join('').length < countStart + countEnd) {
    return char;
  }
  return `${char.slice(0, countStart)}...${char.slice(
    char.length - countEnd,
    char.length
  )}`;
};

export { formaterFileName, compactCharter }
