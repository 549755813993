import {PayloadAction} from '@reduxjs/toolkit'
import {showToastr} from 'app/helpers/toastr'
import {getNotificationAPI, getNotificationDetailAPI} from 'app/services/notificationService'
import {IResponsesErrorCommon} from 'app/types/commonTypes'
import {call, put, takeLatest} from 'redux-saga/effects'
import {
  getNotification,
  getNotificationSuccess,
  getNotificationDetail,
  getNotificationDetailSuccess,
} from './slice'

export function* notificationSaga() {
  yield takeLatest(
    getNotification.type,
    function* notificationRequested(action: PayloadAction<any>) {
      try {
        const {data} = yield call(getNotificationAPI, action.payload)
        yield put(getNotificationSuccess(data))
      } catch (error: unknown) {
        const err = error as IResponsesErrorCommon<any>
        yield showToastr(err.data.meta.message, 'fail')
      }
    }
  )

  yield takeLatest(
    getNotificationDetail.type,
    function* notificationDetailRequested(action: PayloadAction<{id: string}>) {
      try {
        const {data} = yield call(getNotificationDetailAPI, action.payload.id)
        yield put(getNotificationDetailSuccess(data.data))
      } catch (error: unknown) {
        const err = error as IResponsesErrorCommon<any>
        yield showToastr(err.data.meta.message, 'fail')
      }
    }
  )
}
