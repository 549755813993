import {createSlice, current, PayloadAction} from '@reduxjs/toolkit'
import { IResponsesCommon } from 'app/types/commonTypes'

export interface IEvent {
  uuid?: string,
  name: string,
  description: string,
  image: IEventImage,
  created_at: string,
  start_date: string,
  end_date: string
}

export interface IEventImage {
  path: string,
  name: string
}

export interface ContainerEventState {
  events: IResponsesCommon<IEvent>
}

export const initialState: ContainerEventState = {
  events: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as IEvent[]
    }
  } as IResponsesCommon<IEvent>
}

const eventSlice = createSlice({
  name: 'event-state',
  initialState,
  reducers: {
    getListEvent(state, action: PayloadAction<any>) {},
    getListEventSuccess: (state, action: PayloadAction<IResponsesCommon<IEvent>>) => {
      state.events = action.payload
    },
    updateEvent: (state, action: PayloadAction<IEvent>) => {
      const tempState = [...current(state).events.data.data];
      const tempIndex = tempState.findIndex((el) => el.uuid === action.payload.uuid)
      if(tempIndex >= 0) {
        state.events.data.data[tempIndex] = action.payload
      }
    },
  }
})

export const { getListEvent, getListEventSuccess, updateEvent } = eventSlice.actions

export default eventSlice.reducer
