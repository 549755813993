import {PayloadAction} from '@reduxjs/toolkit'
import {showToastr} from 'app/helpers/toastr'
import {
  getInforDashboardAssistantAPI,
  getInforDashboardCommunityAPI,
  getInforDashboardStateAPI,
  getInfoTopDBDashboardAPI,
} from 'app/services/dashboardService'
import {IResponsesErrorCommon} from 'app/types/commonTypes'
import {call, put, takeLatest} from 'redux-saga/effects'
import {
  getInforAssistantDashboard,
  getInforAssistantDashboardSuccess,
  getInforCommunityDashboard,
  getInforCommunityDashboardSuccess,
  getInforStateDashboard,
  getInforStateDashboardSuccess,
  PayloadActionDashboard,
  getInfoTopDBDashboard,
  getInfoTopDBDashboardSuccess
} from './slice'

function* getInforDashboardRequested(action: PayloadAction<PayloadActionDashboard>) {
  try {
    const {data} = yield call(getInforDashboardStateAPI, action.payload)
    const labelXaxis = Object.keys(data.data)
    const seriesRent = labelXaxis.map((item) => data.data[item].rent as number)
    const seriesCash = labelXaxis.map((item) => data.data[item].cash)
    const result = {
      labels: labelXaxis,
      seriesRent,
      seriesCash,
    }
    yield put(getInforStateDashboardSuccess(result))
  } catch (error: unknown) {
    const err = error as IResponsesErrorCommon<any>
    yield showToastr(err.data.meta.message, 'fail')
  }
}

function* getInforAssistantDashboardRequested(action: PayloadAction) {
  try {
    const {data} = yield call(getInforDashboardAssistantAPI, action.payload)
    yield put(getInforAssistantDashboardSuccess(data.data))
  } catch (error: unknown) {
    const err = error as IResponsesErrorCommon<any>
    yield showToastr(err.data.meta.message, 'fail')
  }
}

function* getInforCommunityDashboardRequested(action: PayloadAction) {
  try {
    const {data} = yield call(getInforDashboardCommunityAPI, action.payload)
    yield put(getInforCommunityDashboardSuccess(data.data))
  } catch (error: unknown) {
    const err = error as IResponsesErrorCommon<any>
    yield showToastr(err.data.meta.message, 'fail')
  }
}

function* getInfoTopDBDashboardRequested(action: PayloadAction) {
  try {
    const {data} = yield call(getInfoTopDBDashboardAPI, action.payload)
    yield put(getInfoTopDBDashboardSuccess(data.data))
  } catch (error: unknown) {
    const err = error as IResponsesErrorCommon<any>
    yield showToastr(err.data.meta.message, 'fail')
  }
}

export function* dashboardSaga() {
  yield takeLatest(getInforStateDashboard.type, getInforDashboardRequested)
  yield takeLatest(getInforAssistantDashboard.type, getInforAssistantDashboardRequested)
  yield takeLatest(getInforCommunityDashboard.type, getInforCommunityDashboardRequested)
  yield takeLatest(getInfoTopDBDashboard.type, getInfoTopDBDashboardRequested)
}
