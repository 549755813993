import { showToastr } from 'app/helpers/toastr';
import { getRenleaseAPI, getRenleaseDetailAPI } from 'app/services/renLeaseService';
import { IResponsesErrorCommon } from 'app/types/commonTypes';
import { call, put, takeLatest} from 'redux-saga/effects'
import { getRenLease, getRenLeaseSuccess, getRenLeaseDetail, getRenLeaseDetailSuccess} from './slice'

export function* renLeaseSaga() {
  yield takeLatest(getRenLease.type, function* renLeaseRequested(action: any) {    
    try {
      const { data } = yield call(getRenleaseAPI, action.payload);    
      yield put(getRenLeaseSuccess(data));
    } catch (error: unknown) {
      const err = error as IResponsesErrorCommon<any>
      yield showToastr(err.data.meta.message, 'fail')
    }
  })
  yield takeLatest(getRenLeaseDetail.type, function* renLeaseRequested(action: any) {    
    try {
      const { data } = yield call(getRenleaseDetailAPI, action.payload.uuid);    
      yield put(getRenLeaseDetailSuccess(data.data));
    } catch (error: unknown) {
      const err = error as IResponsesErrorCommon<any>
      yield showToastr(err.data.meta.message, 'fail')
    }
  })
}