import XLSX from 'xlsx'
import {ColumnDescription} from 'react-bootstrap-table-next'
import React from 'react'
import ReactDomServer from 'react-dom/server'
import get from 'lodash/get'

const download = (url: string, name: string) => {
  let a = document.createElement('a')
  a.href = url
  a.download = name
  a.click()

  window.URL.revokeObjectURL(url)
}

const s2ab = (s: any) => {
  const buf = new ArrayBuffer(s.length)

  const view = new Uint8Array(buf)

  for (let i=0; i !== s.length; ++i)
    view[i] = s.charCodeAt(i) & 0xFF
  return buf
}

const removeTags = (str: string) => {
  if ((str===null) || (str===''))
    return false;
  else
    str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace( /(<([^>]+)>)/ig, '');
}

const downloadExcel = (list: any, columns: ColumnDescription[], except: string[] = [], name: string) => {
  const data = generateData(list, columns, except)
  let  ws = XLSX.utils.json_to_sheet(data)
  let wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  // wb.SheetNames.push('')
  // wb.Sheets[''] = ws


  const wbout = XLSX.write(wb, {bookType:'xlsx', bookSST:true, type: 'binary', cellStyles: true})


  // let url = window.URL.createObjectURL(new Blob([s2ab(wbout)], {type:'application/octet-stream'}))
  let url = window.URL.createObjectURL(new Blob([s2ab(wbout)], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'}))

  download(url, name + '.xlsx')
}

const generateData = (list: any, columns: ColumnDescription[], except: any) => {
  const columnsMap = {} as any
  columns.map((item) => {
    if(except.includes(item.dataField)) return;
    columnsMap[item.dataField] = {
      dataField: item.dataField,
      text: item.text
    }
    if(item.formatter) columnsMap[item.dataField]['formatter'] = item.formatter
    if(item.formatExtraData) columnsMap[item.dataField]['formatExtraData'] = item.formatExtraData
  })
  return list.map((item: any) => {
    const value = {} as any
    Object.keys(columnsMap).map((key) => {
      const column = columnsMap[key] as any
      if (column.formatter) {
        const extraData = column.formatExtraData ? column.formatExtraData : null
        if(React.isValidElement(column.formatter(get(item, key, '-'), item, null, extraData))) {
          value[column.text] = removeTags(ReactDomServer.renderToStaticMarkup(column.formatter(get(item, key, '-'), item, null, extraData)))
        } else {
          value[column.text] = column.formatter(get(item, key, '-'), item, null, null)
        }
      }
      else value[column.text] = get(item, key, '-')
    })
    return value
  })
}

export default downloadExcel