import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IUser} from 'app/modules/management/users/users/redux/slice'
import {IResponsesCommon} from 'app/types/commonTypes'

export interface INotification {
  uuid: string
  user_id: number
  title: string
  content: string
  views: number
  type: string
  user: IUser
  is_fixed: boolean
  created_at: string
  updated_at: string
}
export interface ContainerNotificationtate {
  notifications: IResponsesCommon<INotification>
  notifi: INotification //Detail
}

export const initialState: ContainerNotificationtate = {
  notifications: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as INotification[],
    },
  } as IResponsesCommon<INotification>,
  notifi: {} as INotification,
}

const notificationSlice = createSlice({
  name: 'notification-state',
  initialState,
  reducers: {
    getNotification(state, action: PayloadAction<any>) {},
    getNotificationSuccess: (state, action: PayloadAction<IResponsesCommon<INotification>>) => {
      state.notifications = action.payload
    },
    getNotificationDetail(state, action: PayloadAction<{id: string}>) {},
    getNotificationDetailSuccess: (state, action: PayloadAction<INotification>) => {
      state.notifi = action.payload
    },
  },
})

export const {
  getNotification,
  getNotificationSuccess,
  getNotificationDetail,
  getNotificationDetailSuccess,
} = notificationSlice.actions

export default notificationSlice.reducer
