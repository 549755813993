import { IResponsesCommon } from 'app/types/commonTypes';
import axios, { AxiosResponse } from 'axios'
import {ICategory} from '../modules/categories/redux/slice'

const CATEGORY_URL = `categories`

export const getCategoryAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<ICategory>>> => {
  let requestURL = `${CATEGORY_URL}`;
  return axios.get(requestURL, {
    params
  })
};