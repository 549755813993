const fetchApi = {
  BASE_URL: '',
  DEFAULT_OPTIONS: {
    cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *omit
    mode: 'cors', // no-cors, *same-origin
    redirect: 'follow', // *manual, follow, error
    referrer: 'no-referrer' // *client
  },
  defaultHeader: {
    Accept: 'application/json',
    'user-agent': 'Mozilla/4.0 MDN',
    'content-type': 'application/json',
    'Authorization': '',
  },
  getDataFromApi(url: string, opts: object, params: any) {
    const urlSearch = new URLSearchParams(params);
    //console.log("BASE_URL : "+ this.BASE_URL);
    //console.log("url : "+ url);

    return fetch(`${this.BASE_URL}/${url}?${urlSearch}`, opts)
      .then((response) => {
        // Shorthand to check for an HTTP 2xx response status.
        if (response.ok) {
          return response;
        }
        // Raise an exception to reject the promise and trigger the outer .catch() handler.
        // By default, an error response status (4xx, 5xx) does NOT cause the promise to reject!
        throw response;
      })
      .then(function (response) {
        return response.json();
      })
      .catch((error) => {
        if (error.json) {
          // a response throwed above
          return error.json().then((json: any) => {
            const err = Object.assign({}, json, {
              status: error.status,
              statusText: error.statusText
            });
            return Promise.reject(err);
          });
        }
        console.error(error);
        return Promise.reject(error);
      });
  },
  getData(url: string, params?: any){
    const {
      auth: {accessToken},
    } = this.store.getState()

    if(accessToken) {
      this.defaultHeader.Authorization = `Bearer ${accessToken}`
    }

    const method = 'GET';
    const headers = {
      ...this.defaultHeader,
    }
    const opts = { method, headers, ...this.DEFAULT_OPTIONS }
    return this.getDataFromApi(url, opts, params)
  },
  store: {} as any,
}

export default fetchApi