import {createSlice, current, PayloadAction} from '@reduxjs/toolkit'
import {IResponsesCommon, IResponsesDetailCommon} from 'app/types/commonTypes'

export interface IPoint {
  id?: number,
  uuid?: string,
  user_id?: number,
  coupon_id?: number | null,
  content: string,
  points: number,
  type: string,
  status: 1 | 2 | 3,
  created_at: string,
  start_date: string,
}

export interface ContainerEventState {
  points: IResponsesCommon<IPoint>,
  point: IResponsesDetailCommon<IPoint>
}

export const initialState: ContainerEventState = {
  points: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as IPoint[]
    }
  } as IResponsesCommon<IPoint>,
  point: {
    data: {} as IPoint
  } as IResponsesDetailCommon<IPoint>
}

const pointSlice = createSlice({
  name: 'point-state',
  initialState,
  reducers: {
    getListPoints(state, action: PayloadAction<any>) {},
    getListPointsSuccess: (state, action: PayloadAction<IResponsesCommon<IPoint>>) => {
      state.points = action.payload
    },
  }
})

export const { getListPoints, getListPointsSuccess } = pointSlice.actions

export default pointSlice.reducer
