import { PayloadAction } from '@reduxjs/toolkit';
import { showToastr } from 'app/helpers/toastr';
import { getReportAPI, getReportDetailAPI } from 'app/services/reportService';
import { IResponsesErrorCommon } from 'app/types/commonTypes';
import { call, put, takeLatest} from 'redux-saga/effects'
import { getReportPost, getReportPostSuccess, getReportPostDetail, getReportPostDetailSuccess, 
  getReportComment, getReportCommentSuccess, 
  getReportUser, getReportUserSuccess } from './slice'

export function* reportSaga() {
  yield takeLatest(getReportPost.type, function* reportRequested(action: any) {    
    try {
      const { data } = yield call(getReportAPI, action.payload, 'post');    
      yield put(getReportPostSuccess(data));
    } catch (error: unknown) {
      const err = error as IResponsesErrorCommon<any>
      yield showToastr(err.data.meta.message, 'fail')
    }
  })
  yield takeLatest(getReportPostDetail.type, function* reportRequested(action: PayloadAction<{ id: string }>) {    
    try {
      const { data } = yield call(getReportDetailAPI, action.payload.id); 
      yield put(getReportPostDetailSuccess(data.data));
    } catch (error: unknown) {
      const err = error as IResponsesErrorCommon<any>
      yield showToastr(err.data.meta.message, 'fail')
    }
  })
  yield takeLatest(getReportComment.type, function* reportRequested(action: any) {    
    try {
      const { data } = yield call(getReportAPI, action.payload, 'comment');    
      yield put(getReportCommentSuccess(data));
    } catch (error: unknown) {
      const err = error as IResponsesErrorCommon<any>
      yield showToastr(err.data.meta.message, 'fail')
    }
  })
  yield takeLatest(getReportUser.type, function* reportRequested(action: any) {    
    try {
      const { data } = yield call(getReportAPI, action.payload, 'user');    
      yield put(getReportUserSuccess(data));
    } catch (error: unknown) {
      const err = error as IResponsesErrorCommon<any>
      yield showToastr(err.data.meta.message, 'fail')
    }
  })
}