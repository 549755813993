import { call, put, takeLatest} from 'redux-saga/effects'
import {getListCar, getListCarSuccess} from './slice'
import {setLoading} from 'app/redux/loadingSlice'
import { getCarAPI } from 'app/services/carService'

export function* carSaga() {
  //list
  yield takeLatest(getListCar.type, function* eventRequested(action: any) {
    const { data } = yield call(getCarAPI, action.payload);
    yield put(getListCarSuccess(data));
  })
}