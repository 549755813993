import {IRenLease} from 'app/modules/query/database/RenLease/redux/slice'
import {IResponsesCommon, IResponsesUpdateCommon} from 'app/types/commonTypes'
import axios, {AxiosResponse} from 'axios'

const RENLEASE_URL = `rents`

export const getRenleaseAPI = (
  params: any
): Promise<AxiosResponse<IResponsesCommon<IRenLease>>> => {
  return axios.get(RENLEASE_URL, {
    params: {
      ...params,
      orderBy: 'created_at',
      sortedBy: 'desc',
      types: 1,
      with: 'manufacturer;carType;carLevel;engineType;user;options;insideColor;outsideColor;attachments;memo;evaluationResults',
    },
  })
}

export const getRenleaseDetailAPI = (
  uuid: string
): Promise<AxiosResponse<IResponsesCommon<IRenLease>>> => {
  return axios.get(`${RENLEASE_URL}/${uuid}`, {
    params: {
      with: 'carLevel.image;financialCompanies;engineType',
    },
  })
}

export const createRenleaseAPI = (
  data: any
): Promise<AxiosResponse<IResponsesUpdateCommon<IRenLease>>> => {
  return axios.post(RENLEASE_URL, data)
}
export const updateStatusRenleaseAPI = (
  id: string,
  status: any
): Promise<AxiosResponse<IResponsesUpdateCommon<IRenLease>>> => {
  let requestURL = `${RENLEASE_URL}/${id}/status`
  return axios.put(requestURL, {status})
}

export const updateRenleaseAPI = (
  id: string,
  data: any
): Promise<AxiosResponse<IResponsesUpdateCommon<IRenLease>>> => {
  let requestURL = `${RENLEASE_URL}/${id}`
  return axios.put(requestURL, data)
}

export const createRenleaseEvaluationAPI = (
  id: string,
  data: any
): Promise<AxiosResponse<IResponsesUpdateCommon<IRenLease>>> => {
  let requestURL = `${RENLEASE_URL}/${id}/evaluation-results`
  return axios.post(requestURL, data)
}

export const onSaveRenleaseAttachmentAPI = (
  id: string,
  idAttachment: string
): Promise<AxiosResponse<IResponsesUpdateCommon<IRenLease>>> => {
  let requestURL = `${RENLEASE_URL}/${id}/${idAttachment}/save`
  return axios.post(requestURL)
}

export const deleteRenleaseAPI = (
  id: string
): Promise<AxiosResponse<IResponsesUpdateCommon<IRenLease>>> => {
  let requestURL = `${RENLEASE_URL}/${id}`
  return axios.delete(requestURL)
}

export const createRenleaseMemoAPI = (
  id: string,
  data: any
): Promise<AxiosResponse<IResponsesUpdateCommon<IRenLease>>> => {
  return axios.post(`${RENLEASE_URL}/${id}/memo`, data)
}

export const finishAttachmentsRentleaseAPI = (
  id: string,
  data: any
): Promise<AxiosResponse<IResponsesUpdateCommon<IRenLease>>> => {
  return axios.post(`${RENLEASE_URL}/${id}/finish-attachments`, data)
}