import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IResponsesCommon } from 'app/types/commonTypes'

export interface ICategory {
  uuid?: string,
  id?: number,
  name: string,
  parent_id: number,
  description: string,
  status: number
}

export interface ContainerFAQState {
  categories: IResponsesCommon<ICategory>
}

export const initialState: ContainerFAQState = {
    categories: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as ICategory[]
    }
  } as IResponsesCommon<ICategory>
}

const categorySlice = createSlice({
  name: 'category-state',
  initialState,
  reducers: {
    //list
    getListCategory(state, action: PayloadAction<any>) { },
    getListCategorySuccess: (state, action: PayloadAction<IResponsesCommon<ICategory>>) => {
      state.categories = action.payload
    },
  }
})

export const { getListCategory, getListCategorySuccess } = categorySlice.actions

export default categorySlice.reducer
