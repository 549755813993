import {combineReducers} from 'redux'
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import {all} from 'redux-saga/effects'

import * as auth from '../../app/modules/auth'
import loadingReducer from '../../app/redux/loadingSlice'
import faqReducer from 'app/modules/management/other/faq/redux/slice'
import { faqSaga } from 'app/modules/management/other/faq/redux/saga';
import eventReducer from 'app/modules/management/events/event/redux/slice'
import { eventSaga } from '../../app/modules/management/events/event/redux/saga'

import couponReducer from 'app/modules/management/events/coupon/redux/slice'
import { couponSaga } from '../../app/modules/management/events/coupon/redux/saga'

import userReducer from 'app/modules/management/users/users/redux/slice'
import { userSaga } from 'app/modules/management/users/users/redux/saga'

import ReportReduce from 'app/modules/management/communities/report/redux/slice'
import { reportSaga } from 'app/modules/management/communities/report/redux/saga';

import PostReduce from 'app/modules/management/communities/post/redux/slice'
import { postSaga } from 'app/modules/management/communities/post/redux/saga';

import VideoReduce from 'app/modules/management/other/youtube/redux/slice'
import { videoSaga } from 'app/modules/management/other/youtube/redux/saga';

import renLeaseReducer from 'app/modules/query/database/RenLease/redux/slice'
import { renLeaseSaga } from 'app/modules/query/database/RenLease/redux/saga';

import installmentCashReducer from 'app/modules/query/database/InstallmentCash/redux/slice'
import { installmentCashSaga } from 'app/modules/query/database/InstallmentCash/redux/saga';
import carReducer from 'app/modules/management/car/redux/slice'
import { carSaga } from 'app/modules/management/car/redux/saga';

import carReviewReducer from 'app/modules/management/car_review/redux/slice'
import { carReviewSaga } from 'app/modules/management/car_review/redux/saga';

import categoryReducer from 'app/modules/categories/redux/slice'
import { categorySaga } from 'app/modules/categories/redux/saga';

import manufacturerReducer from 'app/modules/manufacturers/redux/slice'
import { manufacturerSaga } from 'app/modules/manufacturers/redux/saga';

import assistantReducer from 'app/modules/management/users/assistants/redux/slice'
import { assistantSaga } from 'app/modules/management/users/assistants/redux/saga'

import notificationReducer from 'app/modules/query/notification/redux/slice';
import { notificationSaga } from 'app/modules/query/notification/redux/saga';

import dealerReducer from 'app/modules/management/users/dealers/redux/slice'
import { dealerSaga } from 'app/modules/management/users/dealers/redux/saga'

import optionsReducer from 'app/redux/optionsCommonSlice';
import { optionsStateSaga } from 'app/redux/optionsCommonSlice';

import pointReducer from 'app/modules/management/events/point/redux/slice'
import { pointSaga } from 'app/modules/management/events/point/redux/saga'

import alarmReducer from 'app/modules/management/alarm/redux/slice'
import { alarmSaga } from 'app/modules/management/alarm/redux/saga';

import carFavoriteReducer from 'app/modules/management/other/favorite-car/redux/slice'
import { carFavoriteSaga } from 'app/modules/management/other/favorite-car/redux/saga';

import { exportSaga } from 'app/modules/management/quotes/export/redux/saga';
import exportReducer from 'app/modules/management/quotes/export/redux/slice';

import { dashboardSaga } from 'app/pages/dashboard/redux/saga';
import dashboardReducer from 'app/pages/dashboard/redux/slice';

import notificationCounterReducer from '../../app/redux/notificationCounterSlice'

import { quotesRenLeaseSaga } from 'app/modules/management/quotes/database/RenLease/redux/saga'
import quotesRentLeaseReducer from 'app/modules/management/quotes/database/RenLease/redux/slice'

import {quotesInstallmentCashSaga} from 'app/modules/management/quotes/database/InstallmentCash/redux/saga'
import quotesInstallmentCashReducer from 'app/modules/management/quotes/database/InstallmentCash/redux/slice'

import quotesReducer from 'app/modules/management/quotes/compare/redux/slice'
import {quotesSaga} from 'app/modules/management/quotes/compare/redux/saga'


export const rootReducer = combineReducers({
  auth: auth.reducer,
  loading: loadingReducer,
  faq: faqReducer,
  event: eventReducer,
  coupon: couponReducer,
  report: ReportReduce,
  post: PostReduce,
  video: VideoReduce,
  user: userReducer,
  renLease: renLeaseReducer,
  installmentCash: installmentCashReducer,
  car: carReducer,
  car_review: carReviewReducer,
  category: categoryReducer,
  manufacturer: manufacturerReducer,
  assistants: assistantReducer,
  notifications: notificationReducer,
  dealer: dealerReducer,
  optionsState: optionsReducer,
  point: pointReducer,
  alarm: alarmReducer,
  carFavorite: carFavoriteReducer,
  export: exportReducer,
  dashboard: dashboardReducer,
  notificationCounter: notificationCounterReducer,
  quoteRentLease: quotesRentLeaseReducer,
  quoteInstallmentCash: quotesInstallmentCashReducer,
  quotes: quotesReducer
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    auth.saga(),
    faqSaga(),
    eventSaga(),
    couponSaga(),
    reportSaga(),
    postSaga(),
    videoSaga(),
    userSaga(),
    renLeaseSaga(),
    installmentCashSaga(),
    carSaga(),
    carReviewSaga(),
    categorySaga(),
    manufacturerSaga(),
    notificationSaga(),
    dealerSaga(),
    optionsStateSaga(),
    assistantSaga(),
    pointSaga(),
    alarmSaga(),
    carFavoriteSaga(),
    exportSaga(),
    dashboardSaga(),
    quotesRenLeaseSaga(),
    quotesInstallmentCashSaga(),
    quotesSaga(),
  ])
}
