import { call, put, takeLatest} from 'redux-saga/effects'
import {getListPoints, getListPointsSuccess} from './slice'
import {getPointsAPI} from '../../../../../services/pointService'


export function* pointSaga() {
  yield takeLatest(getListPoints.type, function* pointRequested(action: any) {
    const { data } = yield call(getPointsAPI, action.payload);
    yield put(getListPointsSuccess(data));
  })
}