import axios, {AxiosResponse} from 'axios'

const QUOTE_URL = `quotes`

export const getCompareQuoteAPI = (params: any) => {
  return axios.get(QUOTE_URL, {
    params: {
      ...params,
      orderBy: 'created_at',
      sortedBy: 'desc',
      with: 'manufacturer;carType;carLevel;engineType;user;options;insideColor;outsideColor'
    }
  })
}

export const deleteCompareQuoteAPI = (uuid: any) => {
  return axios.post(`${QUOTE_URL}/delete-multiple`, {
    uuid
  })
}

export const updateCompareQuoteAPI = (uuid: any, data: any) => {
  return axios.put(`${QUOTE_URL}/${uuid}`, {
    ...data
  })
}