/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import Login from './components/Login'



export function AuthPage() {
  return (
    <Switch>
      <Route path='/auth/login' component={Login} />
      <Redirect from='/auth' exact={true} to='/auth/login' />
      <Redirect to='/auth/login' />
    </Switch>
  )
}
