import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IResponsesCommon, IResponsesDetailCommon} from 'app/types/commonTypes'
import { boolean } from 'yup/lib/locale'

export interface IUser {
  id?: number,
  uuid: string,
  name: string,
  email: string,
  username: string,
  level: number,
  job_title: number,
  email_verified_at: string,
  verify_code: string,
  status: number,
  is_admin: string,
  is_notify: string,
  is_event_notify: string,
  is_kakaotalk_notify: string,
  is_new_car_notify: string,
  is_community_notify: string,
  is_dark_mode: string,
  avatar: string,
  phone: string,
  gender: string,
  birthday: string,
  points: string,
  join_type: string,
  posts_count: number,
  comments_count: number,
  consulting_scheduled_rents_count? : number,
  completing_consultation_rents_count?: number,
  completing_contract_rents_count?: number,
  completing_handover_rents_count?: number,
  today_consulting_rents_count?: number,
  today_consulting_scheduled_rents_count?: number,
  today_completing_consultation_rents_count?: number,
  today_completing_contract_rents_count?: number,
  today_completing_handover_rents_count?: number,
  current_month_consulting_scheduled_rents_count: number,
  current_month_completing_consultation_rents_count: number,
  current_month_completing_contract_rents_count: number,
  current_month_completing_handover_rents_count: number,
  current_month_total_db: number
  total_db: number
  dealer_consulting_scheduled_rents_count? : number,
  dealer_completing_consultation_rents_count?: number,
  dealer_completing_contract_rents_count?: number,
  dealer_completing_handover_rents_count?: number,
  dealer_today_consulting_rents_count?: number,
  dealer_today_consulting_scheduled_rents_count?: number,
  dealer_today_completing_consultation_rents_count?: number,
  dealer_today_completing_contract_rents_count?: number,
  dealer_today_completing_handover_rents_count?: number,
  dealer_current_month_consulting_scheduled_rents_count?: number,
  dealer_current_month_completing_consultation_rents_count?: number,
  dealer_current_month_completing_contract_rents_count?: number,
  dealer_current_month_completing_handover_rents_count?: number,
  is_online?: number,
  manufacturer?: {
    name: string
  },
  created_at: string,
  manufacturer_id?: number,
  deleted_at: string
  exited_at: string
  current_month_consulting_rents_count?: number,
  consulting_rents_count?: number
  dealer_current_month_consulting_rents_count?: number,
  dealer_consulting_rents_count?: number
}

export interface ContainerUserState {
  users: IResponsesCommon<IUser>,
  user: IResponsesDetailCommon<IUser>
  hasExisted: boolean
}

export const initialState: ContainerUserState = {
  users: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as IUser[],
    },
  } as IResponsesCommon<IUser>,
  user: {
    data: {} as IUser
  } as IResponsesDetailCommon<IUser>,
  hasExisted: false
}

const userSlice = createSlice({
  name: 'user-state',
  initialState,
  reducers: {
    //list
    getListUser(state, action: PayloadAction<any>) {},
    getListUserSuccess: (state, action: PayloadAction<IResponsesCommon<IUser>>) => {
      state.users = action.payload
      state.hasExisted = true
    },

    //detail
    getUserDetail(state, action: PayloadAction<string>) {},
    getUserDetailSuccess: (state, action: PayloadAction<IResponsesDetailCommon<IUser>>) => {
      state.user = action.payload
    }
  },
})

export const { getListUser, getListUserSuccess, getUserDetail, getUserDetailSuccess } = userSlice.actions

export default userSlice.reducer
