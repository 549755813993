/* eslint-disable jsx-a11y/anchor-is-valid */
import {useAppDispatch, useAppSelector} from 'app/hooks'
import React, {useEffect, useState} from 'react'
import {KTSVG} from '_metronic/helpers/components/KTSVG'
import {selectCommunityDB} from '../redux/selector'
import {getInforCommunityDashboard} from '../redux/slice'
import moment from 'moment'
import 'moment/locale/ko' // without this line it didn't work
moment.locale('ko')

type Props = {
  className: string
}

const TIME_FORMAT = 'MMM Do'

const CommunityStatus: React.FC<Props> = ({className}) => {
  const communityDB = useAppSelector(selectCommunityDB)

  const [step, setStep] = useState<number>(-1)
  const [typeTime, setTypeTime] = useState<'days' | 'months'>('days')

  const getGroupsTime = React.useMemo(() => {
    return {
      prevTime: moment().subtract(step + 2, typeTime),
      nextTime: moment().subtract(step, typeTime),
      currentTime: moment().subtract(step + 1, typeTime),
    }
  }, [step, typeTime])

  const dispatch = useAppDispatch()

  useEffect(() => {
    const FormatTimeAPI = typeTime === 'days' ? 'YYYY-MM-DD' : 'YYYY-MM'
    dispatch(
      getInforCommunityDashboard({
        filter_by: typeTime === 'days' ? 'date' : 'month',
        time: moment(getGroupsTime.currentTime).format(FormatTimeAPI),
      })
    )
  }, [dispatch, typeTime, getGroupsTime])

  const onClickPrevTime = () => {
    setStep((prevState) => prevState + 1)
  }

  const onClickNextTime = React.useCallback(() => {
    const currentTime = moment(getGroupsTime.nextTime).toDate().getTime()
    const tempTime = new Date().getTime()
    if (currentTime < tempTime) {
      setStep((prevState) => prevState - 1)
    }
  }, [getGroupsTime])

  const checkNumber = (nextNumber = 0, prevNumber = 0) => {
    const currentNumber = nextNumber - prevNumber
    if (currentNumber > 0) {
      return {
        number: currentNumber,
        down: true,
      }
    } else if(currentNumber < 0) {
      return {
        number: currentNumber * -1,
        down: false,
      }
    } else {
      return {
        number: 0,
        down: false,
        hidden: true
      }
    }
  }

  return (
    <div className={`card box-shadow-primary ${className}`}>
      <div className='card-header border-1 border-bottom border-gray-680'>
        <h3 className='card-title align-items-start flex-column flex-root'>
          <span className='card-label fs-3'>App / 커뮤니티 현황</span>
        </h3>
        <div className='card-toolbar gap-10'>
          <div className='d-flex align-items-center'>
            <KTSVG
              path='/media/icons/duotune/arrows/arr074.svg'
              className='svg-icon-1 svg-icon-gray-250 svg-icon-2x me-4 cursor-pointer'
              onClick={onClickPrevTime}
            />
            {moment(getGroupsTime.prevTime).format(TIME_FORMAT)}
          </div>
          <div className='text-color-blue'>
            {moment(getGroupsTime.currentTime).format(TIME_FORMAT)}
          </div>
          <div className='d-flex align-items-center text-color-gray-nobel'>
            {moment(getGroupsTime.nextTime).format(TIME_FORMAT)}
            <KTSVG
              path='/media/icons/duotune/arrows/arr071.svg'
              className='svg-icon-1 svg-icon-gray-250 svg-icon-2x ms-4 cursor-pointer'
              onClick={onClickNextTime}
            />
          </div>
        </div>
        <div className='card-toolbar flex-root justify-content-end'>
          <div className='card-toolbar'>
            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-white form-select-sm border-0 fs-3 w-fit-content'
              defaultValue={typeTime}
              value={typeTime}
              onChange={(e) => setTypeTime(e.target.value as 'days' | 'months')}
            >
              <option value='days'>일별보기</option>
              <option value='months'>월별보기</option>
            </select>
          </div>
        </div>
      </div>
      <div className='card-body py-3 dashboard-table-wrapper max-height-330'>
        <div className='d-flex row'>
          <div className='status-item col-sm-6 gap-1 mt-5 d-flex align-items-center gap-10 justify-content-center'>
            <div className='status--title'>회원 수</div>
            <div className='d-flex flex-wrap flex-column align-items-center'>
              <div className='d-flex align-items-center'>
                <div className={`status--count text-color-blue`}>
                  {communityDB?.user?.current_total ?? 0}
                </div>
                <span className={`status--subTitle text-color-blue`}>회</span>
              </div>
              {!checkNumber(communityDB?.user?.current_total, communityDB?.user?.preview_total).hidden && (
                <div className='d-flex align-items-center status--point'>
                  <div
                    className={
                      checkNumber(communityDB?.user?.current_total, communityDB?.user?.preview_total)
                        .down
                        ? 'status--point-succes'
                        : 'status--point-danger'
                    }
                  >
                    {
                      checkNumber(communityDB?.user?.current_total, communityDB?.user?.preview_total)
                        .number
                    }
                  </div>
                  <KTSVG
                    path={
                      checkNumber(communityDB?.user?.current_total, communityDB?.user?.preview_total)
                        .down
                        ? '/media/icons/duotune/arrows/arr066.svg'
                        : '/media/icons/duotune/arrows/arr065.svg'
                    }
                    className={`svg-icon-1 me-2 ${
                      checkNumber(communityDB?.user?.current_total, communityDB?.user?.preview_total)
                        .down
                        ? 'svg-icon-success'
                        : 'svg-icon-danger'
                    }`}
                  />
                </div>
              )}
            </div>
          </div>

          <div className='status-item col-sm-6 gap-1 mt-5 d-flex align-items-center gap-10 justify-content-center'>
            <div className='status--title'>게시글</div>
            <div className='d-flex flex-wrap flex-column align-items-center'>
              <div className='d-flex align-items-center'>
                <div className={`status--count text-color-blue`}>
                  {communityDB?.post?.current_total ?? 0}
                </div>
                <span className={`status--subTitle text-color-blue`}>개</span>
              </div>

              {!checkNumber(communityDB?.post?.current_total, communityDB?.post?.preview_total).hidden && (
                <div className='d-flex align-items-center status--point'>
                  <div
                    className={
                      checkNumber(communityDB?.post?.current_total, communityDB?.post?.preview_total)
                        .down
                        ? 'status--point-succes'
                        : 'status--point-danger'
                    }
                  >
                    {
                      checkNumber(communityDB?.post?.current_total, communityDB?.post?.preview_total)
                        .number
                    }
                  </div>
                  <KTSVG
                    path={
                      checkNumber(communityDB?.post?.current_total, communityDB?.post?.preview_total)
                        .down
                        ? '/media/icons/duotune/arrows/arr066.svg'
                        : '/media/icons/duotune/arrows/arr065.svg'
                    }
                    className={`svg-icon-1 me-2 ${
                      checkNumber(communityDB?.post?.current_total, communityDB?.post?.preview_total)
                        .down
                        ? 'svg-icon-success'
                        : 'svg-icon-danger'
                    }`}
                  />
                </div>
              )}
            </div>
          </div>

          <div className='status-item col-sm-6 gap-1 mt-5 d-flex align-items-center gap-10 justify-content-center'>
            <div className='status--title'>댓글</div>
            <div className='d-flex flex-wrap flex-column align-items-center'>
              <div className='d-flex align-items-center'>
                <div className={`status--count`}>{communityDB?.comment?.current_total ?? 0}</div>
                <span className={`status--subTitle`}>개</span>
              </div>

              {!checkNumber(
                      communityDB?.comment?.current_total,
                      communityDB?.comment?.preview_total
                    ).hidden && (
                <div className='d-flex align-items-center status--point'>
                  <div
                    className={
                      checkNumber(
                        communityDB?.comment?.current_total,
                        communityDB?.comment?.preview_total
                      ).down
                        ? 'status--point-succes'
                        : 'status--point-danger'
                    }
                  >
                    {
                      checkNumber(
                        communityDB?.comment?.current_total,
                        communityDB?.comment?.preview_total
                      ).number
                    }
                  </div>
                  <KTSVG
                    path={
                      checkNumber(
                        communityDB?.comment?.current_total,
                        communityDB?.comment?.preview_total
                      ).down
                        ? '/media/icons/duotune/arrows/arr066.svg'
                        : '/media/icons/duotune/arrows/arr065.svg'
                    }
                    className={`svg-icon-1 me-2 ${
                      checkNumber(
                        communityDB?.comment?.current_total,
                        communityDB?.comment?.preview_total
                      ).down
                        ? 'svg-icon-success'
                        : 'svg-icon-danger'
                    }`}
                  />
                </div>

                    )}
            </div>
          </div>

          <div className='status-item col-sm-6 gap-1 mt-5 d-flex align-items-center gap-10 justify-content-center'>
            <div className='status--title'>신고</div>
            <div className='d-flex flex-wrap flex-column align-items-center'>
              <div className='d-flex align-items-center'>
                <div className={`status--count`}>{communityDB?.report?.current_total ?? 0}</div>
                <span className={`status--subTitle`}>회</span>
              </div>
              {!checkNumber(
                      communityDB?.report?.current_total,
                      communityDB?.report?.preview_total
                    ).hidden && (
                <div className='d-flex align-items-center status--point'>
                  <div
                    className={
                      checkNumber(
                        communityDB?.report?.current_total,
                        communityDB?.report?.preview_total
                      ).down
                        ? 'status--point-succes'
                        : 'status--point-danger'
                    }
                  >
                    {
                      checkNumber(
                        communityDB?.report?.current_total,
                        communityDB?.report?.preview_total
                      ).number
                    }
                  </div>
                  <KTSVG
                    path={
                      checkNumber(
                        communityDB?.report?.current_total,
                        communityDB?.report?.preview_total
                      ).down
                        ? '/media/icons/duotune/arrows/arr066.svg'
                        : '/media/icons/duotune/arrows/arr065.svg'
                    }
                    className={`svg-icon-1 me-2 ${
                      checkNumber(
                        communityDB?.report?.current_total,
                        communityDB?.report?.preview_total
                      ).down
                        ? 'svg-icon-success'
                        : 'svg-icon-danger'
                    }`}
                  />
                </div>

                    )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {CommunityStatus}

type StatusItemTypes = {
  title: string
  count: string
  currency: string
  point: string
  is_active: boolean
}

const StatusItem: React.FC<StatusItemTypes> = (props) => {
  return (
    <>
      <div className='status-item col-sm-6 gap-1 mt-5 d-flex align-items-center gap-10 justify-content-center'>
        <div className='status--title'>{props.title}</div>
        <div className='d-flex flex-wrap flex-column align-items-center'>
          <div className='d-flex align-items-center'>
            <div className={`status--count ${props.is_active ? 'text-color-blue' : ''}`}>
              {props.count}
            </div>
            <span className={`status--subTitle ${props.is_active ? 'text-color-blue' : ''}`}>
              {props.currency}
            </span>
          </div>
          <div className='d-flex align-items-center status--point'>
            <div>{props.point}</div>
            <KTSVG
              path='/media/icons/duotune/arrows/arr066.svg'
              className='svg-icon-1 svg-icon-danger me-2'
            />
          </div>
        </div>
      </div>
    </>
  )
}
