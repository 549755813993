import clsx from 'clsx'
import React, {FC, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, QuickLinks, Search} from '../../../partials'
import {useLayout} from '../../core'
import {useAppSelector} from '../../../../app/hooks'
import RefreshButton from '../../../partials/layout/refresh-button/RefreshButton'
import CountDown from '../../../partials/layout/refresh-button/CountDown'
import {useHistory} from 'react-router'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px text-user-name',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const {config} = useLayout()
  const { user } = useAppSelector(state => state.auth)
  const { counter } = useAppSelector(state => state.notificationCounter)
  const history = useHistory()

  const goToNotificationPage = () => {
    history.push('/query/notifications')
  }

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <RefreshButton />
      </div>
      <div className="d-flex align-items-center ms-1 ms-lg-3 ml-57px">
        <div className="btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px">
          <div className="notification-wrapper" onClick={goToNotificationPage}>
            <div className='notification-count'>{counter}</div>
            <i className="fas fa-bell fa-sm icon-bell" />
          </div>
        </div>
      </div>
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          {user.name} <i className="fa fa-chevron-down" />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export {Topbar}
