import { showToastr } from 'app/helpers/toastr';
import { getQuestionAnswerAPI } from 'app/services/questionAnswerSevice';
import { IResponsesErrorCommon } from 'app/types/commonTypes';
import { call, put, takeLatest} from 'redux-saga/effects'
import { getQuestionAnswer, getQuestionAnswerSuccess } from './slice'

export function* faqSaga() {
  yield takeLatest(getQuestionAnswer.type, function* videoRequested(action: any) {    
    try {
      const { data } = yield call(getQuestionAnswerAPI, action.payload);    
      yield put(getQuestionAnswerSuccess(data));
    } catch (error: unknown) {
      const err = error as IResponsesErrorCommon<any>
      yield showToastr(err.data.meta.message, 'fail')
    }
  })
}