import {IResponsesCommon} from 'app/types/commonTypes'
import axios, {AxiosResponse} from 'axios'

const DASHBOARD_URL = `dashboard`

export const getInforDashboardStateAPI = (
  params: any
): Promise<AxiosResponse<IResponsesCommon<any>>> => {
  return axios.get(`${DASHBOARD_URL}/db`, {
    params,
  })
}

export const getInforDashboardAssistantAPI = (
  params: any
): Promise<AxiosResponse<IResponsesCommon<any>>> => {
  return axios.get(`${DASHBOARD_URL}/assistant`, {
    params,
  })
}


export const getInforDashboardCommunityAPI = (
  params: any
): Promise<AxiosResponse<IResponsesCommon<any>>> => {
  return axios.get(`${DASHBOARD_URL}/community`, {
    params,
  })
}

export const getInfoTopDBDashboardAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<any>>> => {
  return axios.get(`${DASHBOARD_URL}/top-db`, {
    params
  })
}
