import { IResponsesCommon, IResponsesUpdateCommon } from 'app/types/commonTypes';
import axios, { AxiosResponse } from 'axios'
import {IManufacturer} from '../modules/manufacturers/redux/slice'

const MANUFACTURER_URL = `manufacturers`

export const getManufacturerAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<IManufacturer>>> => {
  let requestURL = `${MANUFACTURER_URL}`;
  return axios.get(requestURL, {
    params
  })
};