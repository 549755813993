/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {Languages} from './Languages'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {useDispatch} from 'react-redux'
import {toAbsoluteUrl} from '../../../helpers'
import {useAppSelector} from '../../../../app/hooks'
import {get as _get} from 'lodash'
import {DEALER_ROLE, SUPPER_ADMIN} from '../../../../app/contants'
import {maskString} from '../../../../app/helpers'

const HeaderUserMenu: FC = () => {
  const {user} = useAppSelector((state) => state.auth)
  const {REACT_APP_IMAGE_URL} = process.env
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const ROLE = _get(isAuthorized, 'roles[0].id', -1)


  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
  }
  // const avatar =  user.avatar ? `${REACT_APP_IMAGE_URL}/${user.avatar}` : toAbsoluteUrl('/media/logos/avatar-default.svg');
  const avatar = toAbsoluteUrl('/media/avatars/avatar-default.svg')
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded rounded-0 menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-290px bg-gray-780'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content align-items-center px-3 text-center'>
          <div className='symbol symbol-50px me-5'>
            <img className='rounded-circle' alt='Logo' src={avatar} />
          </div>
        </div>
      </div>
      <div className='menu-item px-3 mt-5 mb-10'>
        <table className='w-100 ms-2'>
          <tr>
            <td className='w-50px text-gray-730 font-size-17'>이름</td>
            <td className='text-white font-size-17'>{user.name}</td>

          </tr>
          <tr>
            <td className='w-50px text-gray-730 font-size-17'>ID</td>
            <td className='text-white font-size-17'>{maskString(user.email)}</td>
          </tr>
          <tr>
            <td className='w-50px text-gray-730 font-size-17'>{ROLE === DEALER_ROLE ? '변경' : '직급'}</td>
            <td className='text-white font-size-17'>{ROLE === DEALER_ROLE ? _get(user, 'manufacturer.name', '') : user.job_title}</td>
          </tr>
        </table>
      </div>
      {
        ROLE === SUPPER_ADMIN && (
          <div className='menu-item px-5 my-3'>
            <Link
              to='/manager/users/assistant'
              className='menu-link px-5 rounded-0 bg-gray-650 text-white font-weight-600 text-center d-flex justify-content-center'
            >
              정보수정
            </Link>
          </div>
        )
      }

      <div className='menu-item px-5'>
        <a
          onClick={logout}
          className='menu-link px-5 rounded-0 bg-white text-back font-weight-600 d-flex justify-content-center'
        >
          로그아웃
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
