import {FC} from 'react'

const Error404: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>페이지를 찾을 수 없습니다.</h1>
    </>
  )
}

export {Error404}
