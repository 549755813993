import { IUser } from './../../../modules/management/users/users/redux/slice';
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {CHART_LEGENDS} from 'app/contants/dashboard'
import findIndex from 'lodash/findIndex'

interface SERIES_TYPES {
  name: string
  data?: number[]
}
export interface IStateDashboard {
  labels: string[]
  seriesRent: SERIES_TYPES
  seriesCash: SERIES_TYPES
}
export interface ContainerDashboard {
  stateDB: IStateDashboard
  assistantDB: IUser[]
  communityDB?: any
  topDB: any
}

export const initialState: ContainerDashboard = {
  stateDB: {
    labels: [],
    seriesRent: {
      name: CHART_LEGENDS.RENT,
    },
    seriesCash: {
      name: CHART_LEGENDS.CASH,
    },
  },
  assistantDB: [],
  topDB: [],
}

export interface PayloadActionDashboard {
  filter_by: string
  from?: string
  to?: string
  time?: string
}

export interface PayloadActionTopDBDashboard {
  type?: number
  path?: string
}

const dashboardSlice = createSlice({
  name: 'dashboard-state',
  initialState,
  reducers: {
    getInforStateDashboard(state, action: PayloadAction<PayloadActionDashboard>) {},
    getInforStateDashboardSuccess: (
      state,
      action: PayloadAction<{labels: string[]; seriesRent: number[]; seriesCash: number[]}>
    ) => {
      state.stateDB.labels = action.payload.labels
      state.stateDB.seriesRent.data = action.payload.seriesRent
      state.stateDB.seriesCash.data = action.payload.seriesCash
    },
    getInforAssistantDashboard(state, action: PayloadAction) {},
    getInforAssistantDashboardSuccess: (state, action: PayloadAction<IUser[]>) => {
      state.assistantDB = action.payload
    },
    getInforCommunityDashboard(state, action: PayloadAction<PayloadActionDashboard>) {},
    getInforCommunityDashboardSuccess: (state, action: PayloadAction<IUser[]>) => {
      state.communityDB = action.payload
    },

    // Top DB
    getInfoTopDBDashboard(state, action: PayloadAction<PayloadActionTopDBDashboard>) {},
    getInfoTopDBDashboardSuccess: (state, action: PayloadAction<any>) => {
      state.topDB = action.payload
    },
    setInfoTopDBDashboard: (state, action: PayloadAction<any>) => {
      const { method, id } = action.payload
      const topDB = [...state.topDB]
      switch (method)
      {
        case 'created': {
          topDB.unshift(action.payload)
          if(topDB.length > 5) {
            topDB.pop()
          }
          break;
        }
        case 'updated': {
          const index = findIndex(topDB, { id })
          if(index !== -1) {
            topDB.splice(index, 1, action.payload)
          }
          break;
        }
        case 'deleted': {
          const index = findIndex(topDB, { id })
          if(index !== -1) {
            topDB.splice(index, 1)
          }
          break;
        }
      }
      state.topDB = topDB
    }
  },
})

export const {
  getInforStateDashboard,
  getInforStateDashboardSuccess,
  getInforAssistantDashboard,
  getInforAssistantDashboardSuccess,
  getInforCommunityDashboard,
  getInforCommunityDashboardSuccess,
  getInfoTopDBDashboard,
  getInfoTopDBDashboardSuccess,
  setInfoTopDBDashboard,
} = dashboardSlice.actions

export default dashboardSlice.reducer
