import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IResponsesCommon} from '../../../../../types/commonTypes'

export interface IQuotes {
  quotes: IResponsesCommon<any>
}

export const initialState: IQuotes = {
  quotes: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as any
    }
  } as IResponsesCommon<any>
}

const quoteSlice = createSlice({
  name: 'compare-quotes',
  initialState,
  reducers: {
    getListQuotes(state, action: PayloadAction<any>) {},
    getListQuoteSuccess: (state, action: PayloadAction<IResponsesCommon<any>>) => {
      state.quotes = action.payload
    }
  }
})

export const { getListQuotes, getListQuoteSuccess } = quoteSlice.actions

export default quoteSlice.reducer