import React from 'react'
import {useAppDispatch, useAppSelector} from '../../../../app/hooks'
import {refreshToken} from '../../../../app/modules/auth/redux/AuthCRUD'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {setLoading} from '../../../../app/redux/loadingSlice'
import ReactCountDown, {zeroPad} from 'react-countdown'

const RefreshButton: React.FC = () => {
  const dispatch = useAppDispatch()
  const refresh_token = useAppSelector(state => state.auth.refreshToken)
  const { session_expired } = useAppSelector(state => state.auth)

  const reClaimSessionExpired = () => {
    dispatch(setLoading(true))
    refreshToken(refresh_token).then((data) => {
      const loginData = data.data.data
      const {access_token} = loginData
      dispatch(auth.actions.setAccessToken(access_token))
    })
  }

  const handleExpired = () => {
    dispatch(auth.actions.logout())
  }

  return (
    <>
      <div className='container-fluid d-flex flex-stack'>
        <ReactCountDown
          date={session_expired}
          renderer={(props) => {
            return (
              <span className="time-refresh font-weight-400">
                {
                  `${zeroPad(props.minutes)}:${zeroPad(props.seconds)}`
                }
              </span>
            )
          }}
          onComplete={() => {
            handleExpired()
          }}
        />
        {/*<CountDown expiredAction={handleExpired}/>*/}
        <span className="h-20px border-gray-200 border-start mx-4 border-gray-730 "></span>
        <div className='d-flex align-items-center py-1'>
          <a
            href='#'
            className='btn btn-sm btn-primary button-core-bg font-size-17'
            onClick={reClaimSessionExpired}
          >
            세션연장
          </a>
        </div>
      </div>
    </>
  )
}

export default RefreshButton