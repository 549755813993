import { showToastr } from 'app/helpers/toastr';
import { getVideoAPI } from 'app/services/videoService';
import { IResponsesErrorCommon } from 'app/types/commonTypes';
import { call, put, takeLatest} from 'redux-saga/effects'
import { getVideoManage, getVideoManageSuccess } from './slice'

export function* videoSaga() {
  yield takeLatest(getVideoManage.type, function* videoRequested(action: any) {    
    try {
      const { data } = yield call(getVideoAPI, action.payload);    
      yield put(getVideoManageSuccess(data));
    } catch (error: unknown) {
      const err = error as IResponsesErrorCommon<any>
      yield showToastr(err.data.meta.message, 'fail')
    }
  })
}