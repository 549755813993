import {IResponsesCommon, IResponsesDetailCommon, IResponsesUpdateCommon} from 'app/types/commonTypes'
import axios, { AxiosResponse } from 'axios'
import { IUser } from 'app/modules/management/users/users/redux/slice';
import {USER_NORMAL_ROLE, ASSISTANT_ROLE, DEALER_ROLE} from '../contants'


const USER_URL = `users`

export const getUserAPI = (params: any, role?: number, relations: string = ''): Promise<AxiosResponse<IResponsesCommon<IUser>>> => {
  let requestURL = `${USER_URL}`;
  const requestParams = {
    ...params,
    role,
    with: relations,
    orderBy: 'created_at',
    sortedBy: 'desc',
  }

  if(role === ASSISTANT_ROLE) {
    requestParams['countType'] = 'assistant'
    if(params.search) requestParams['searchFields'] = 'name;job_title'
  }
  if(role === DEALER_ROLE) {
    requestParams['countType'] = 'dealer'
    if(params.search) requestParams['searchFields'] = 'name;manufacturer.name'
  }
  if(role === USER_NORMAL_ROLE) {
    if(params.search) requestParams['searchFields'] = 'name;level;phone;username'
  }

  return axios.get(requestURL, {
    params: requestParams
  })
};

export const getUserDetailAPI = (uuid: string, relations: string = '', countType = ''): Promise<AxiosResponse<IResponsesDetailCommon<IUser>>> => {
  const requestURL = `${USER_URL}/${uuid}`
  const requestParams = {
    role: USER_NORMAL_ROLE,
    with: relations,
  } as any

  if(countType) requestParams['countType'] = countType

  return axios.get(requestURL, { params: requestParams })
}

export const createUserApi = (data: any): Promise<AxiosResponse<IResponsesUpdateCommon<IUser>>> => {
  return axios.post(USER_URL, {
    ...data
  })
}

export const updateUserApi = (uuid: any, data: any): Promise<AxiosResponse<IResponsesUpdateCommon<IUser>>> => {
  const requestUrl = `${USER_URL}/${uuid}`
  return axios.put(requestUrl, data)
}

export const deleteUserApi = (uuid: string[], role: typeof USER_NORMAL_ROLE | typeof ASSISTANT_ROLE | typeof DEALER_ROLE): Promise<AxiosResponse<IResponsesUpdateCommon<IUser>>> => {
  const requestUrl = `${USER_URL}/delete-multiple`
  return axios.post(requestUrl, {
    uuid,
    role
  })
}