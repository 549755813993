import { call, put, takeLatest} from 'redux-saga/effects'
import {getListCarReview, getListCarReviewSuccess} from './slice'
import {setLoading} from 'app/redux/loadingSlice'
import { getCarReviewAPI } from 'app/services/carReviewService'

export function* carReviewSaga() {
  //list
  yield takeLatest(getListCarReview.type, function* eventRequested(action: any) {
    const { data } = yield call(getCarReviewAPI, action.payload);
    yield put(getListCarReviewSuccess(data));
  })
}