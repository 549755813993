import React, {useEffect, useLayoutEffect, useState} from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getNotificationCounter, setNotificationCounter }  from 'app/redux/notificationCounterSlice'

import {
  DrawerMessenger,
  ExploreMain,
  ActivityDrawer,
  Main,
  InviteUsers,
  UpgradePlan,
} from '../partials'
import {MenuComponent} from '../assets/ts/components'
import Loading from '../../app/components/layout/Loading'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { RootState } from 'setup/redux/Store'
import { getOptionsRenstLease } from 'app/redux/optionsCommonSlice'
import useLavavelEcho from '../../app/hooks/useLavavelEcho'

let isConstructor = true
const MasterLayout: React.FC = ({children}) => {
  const location = useLocation()
  const {isLoading, isOptionsLoading} = useAppSelector(state => state.loading)

  const getLoadingState = () => {
    if(isLoading) return true;
    if(isOptionsLoading) return true;
    return false
  }

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useLayoutEffect(() => {
    if (getLoadingState()) {
      document.body.style.overflow = "hidden";
    }
    if (!getLoadingState()) {
      document.body.style.overflow = "auto";
    }
  }, [getLoadingState()]);

  const {hasExisted} = useAppSelector((state: RootState) => state.optionsState)
  const dispatch = useAppDispatch()
  
  useEffect(() => {
    if(isConstructor && !hasExisted) {
      dispatch(getOptionsRenstLease())
    }
    isConstructor = false
  }, [dispatch, hasExisted])

  useLavavelEcho('.notification.unread', 'count-unread-notifications', (data: any) => {
    const { count } = data
    dispatch(setNotificationCounter(count))
  })

  return (
    <PageDataProvider>
      <Loading loading={isLoading}/>
      <Loading loading={isOptionsLoading}/>
      <ToastContainer />
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>{children}</Content>
            </div>

          </div>
           <Footer />
        </div>
      </div>
      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
