import {createSlice, current, PayloadAction} from '@reduxjs/toolkit'
import {IResponsesCommon} from 'app/types/commonTypes'

export interface IReport {
  id: number
  uuid: string
  user_id: number
  commentable_type: string
  type?: number
  commentable_id: number
  parent_id?: number
  content: string
  status: number
  reactions_count: number
  reactions: string[]
  is_like: boolean
  created_at: string
  updated_at: string
  reportable: ReportableType & { commentable : ReportableType }
  user: {
    name: string
    id: string
  }
}

type ReportableType = {
    id: number
    title: string
    content: string
    excerpt: string
    comments_count: number
    likes_count: number
    views_count: number
    created_at: string
    categories: CategorieTypes[]
    image: ImageTypes
    tags: TagTypes[]
    user: {
      name: string
      id: string
    }
}

type CategorieTypes = {
  name: string
  pivot: {
    post_id: number
    category_id: number
  }
}

type ImageTypes = {
  id: number
  name: string
  path: string
  imageable_id: string
}
type TagTypes = {
  name: string
  pivot: {
    post_id: number
    tag_id: number
  }
}

export interface ContainerReporttate {
  reportPosts: IResponsesCommon<IReport>
  reportPost?: IReport
  reportComments: IResponsesCommon<IReport>
  reportComment?: IReport
  reportUsers: IResponsesCommon<IReport>
  reportUser?: IReport
}

export const initialState: ContainerReporttate = {
  reportPosts: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as IReport[],
    },
  } as IResponsesCommon<IReport>,
  reportComments: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as IReport[],
    },
  } as IResponsesCommon<IReport>,
  reportUsers: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as IReport[],
    },
  } as IResponsesCommon<IReport>,
}


const reportSlice = createSlice({
  name: 'report-state',
  initialState,
  reducers: {
    // get report post
    getReportPost(state, action: PayloadAction<any>) {},
    getReportPostSuccess: (state, action: PayloadAction<IResponsesCommon<IReport>>) => {
      state.reportPosts = action.payload
    },
    updateReportPost: (state, action: PayloadAction<IReport>) => {
      const tempState = [...current(state).reportPosts.data.data]
      const tempIndex = tempState.findIndex((el) => el.uuid === action.payload.uuid)
      if (tempIndex >= 0) {
        state.reportPosts.data.data[tempIndex] = action.payload
      }
    },
    getReportPostDetail(state, action: PayloadAction<{id: string | number}>) {},
    getReportPostDetailSuccess: (state, action: PayloadAction<IReport>) => {
      state.reportPost = action.payload
    },
    // get report comment
    getReportComment(state, action: PayloadAction<any>) {},
    getReportCommentSuccess: (state, action: PayloadAction<IResponsesCommon<IReport>>) => {
      state.reportComments = action.payload
    },
    // get report user
    getReportUser(state, action: PayloadAction<any>) {},
    getReportUserSuccess: (state, action: PayloadAction<IResponsesCommon<IReport>>) => {
      state.reportComments = action.payload
    },
  },
})

export const {
  getReportPost,
  getReportPostSuccess,
  getReportPostDetail,
  getReportPostDetailSuccess,
  getReportComment,
  getReportCommentSuccess,
  getReportUser,
  getReportUserSuccess,
} = reportSlice.actions

export default reportSlice.reducer
