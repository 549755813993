import { IResponsesCommon, IResponsesUpdateCommon } from 'app/types/commonTypes';
import axios, { AxiosResponse } from 'axios'
import {ICoupon} from '../modules/management/events/coupon/redux/slice'

const COUPON_URL = `coupons`
const STATSTICAL_COUPON_URL = `coupons-statistic`

export const getCouponAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<ICoupon>>> => {
  let requestURL = `${COUPON_URL}?sortedBy=desc&orderBy=created_at`;
  return axios.get(requestURL, {
    params
  })
};

export const getDetailCouponAPI = (uuid: string): Promise<AxiosResponse<IResponsesCommon<ICoupon>>> => {
  let requestURL = `${COUPON_URL}`;

  return axios.get(`${requestURL}/${uuid}`)
};

export const createCouponApi = (data: any): Promise<AxiosResponse<IResponsesUpdateCommon<ICoupon>>> => {
  return axios.post(COUPON_URL, data)
};

export const updateCouponApi = (id: string, data: any): Promise<AxiosResponse<IResponsesUpdateCommon<ICoupon>>> => {
  let requestURL = `${COUPON_URL}/${id}`;

  return axios.put(requestURL, data)
};


export const removeCouponAPI = (uuids: any): Promise<AxiosResponse<IResponsesCommon<ICoupon>>> => {
  let requestURL = `${COUPON_URL}/delete-multiple`;
  return axios.post(requestURL, {uuid: uuids})
};

export const getStatsticalAPI = (): Promise<AxiosResponse<IResponsesCommon<ICoupon>>> => {
  let requestURL = `${STATSTICAL_COUPON_URL}`;
  return axios.get(requestURL)
};