import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IResponsesCommon } from 'app/types/commonTypes'

export interface ICarReview {
  uuid: string,
  user_id: number,
  car_id: number,
  assistant_id: number,
  title: string,
  buy_type: number,
  excerpt: boolean,
  content: string,
  status: number,
  reacted_at: string,
  buy_date: string,
  likes_count: number,
  comments_count: number,
  views_count: number,
  reactions_count: number,
  image: object,
  is_like: boolean
}

export interface ContainerFAQState {
  car_reviews: IResponsesCommon<ICarReview>
}

export const initialState: ContainerFAQState = {
  car_reviews: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as ICarReview[]
    }
  } as IResponsesCommon<ICarReview>
}

const carReviewSlice = createSlice({
  name: 'car-review-state',
  initialState,
  reducers: {
    //list
    getListCarReview(state, action: PayloadAction<any>) { },
    getListCarReviewSuccess: (state, action: PayloadAction<IResponsesCommon<ICarReview>>) => {
      state.car_reviews = action.payload
    },
  }
})

export const { getListCarReview, getListCarReviewSuccess } = carReviewSlice.actions

export default carReviewSlice.reducer
