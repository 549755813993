import { ASSISTANT_ROLE, DEALER_ROLE, SUPPER_ADMIN } from 'app/contants'
import store from '../../../setup/redux/Store'

const isUserHasRole:any = (roles: any) => {
  const {auth: {user}} = store.getState()
  if(!user) return false
  if(roles instanceof Array) {
    for (let role of roles) {
      return isUserHasRole(role)
    }
  } else {
    for (let role of user.roles) {
      return roles === role.name
    }
  }
  return false;
}
const authorizationStrategy = (role: number, requirement: number[]): boolean => {  
  return requirement.includes(role)
}

const handleRedirectRole = (role: number): string => {
  switch (role) {
    case SUPPER_ADMIN:
      return '/dashboard'
    case ASSISTANT_ROLE:
      return '/query/database'
      case DEALER_ROLE:
        return '/query/database/installment-cash'
    default:
      return '/'
  }
}

export { isUserHasRole, authorizationStrategy, handleRedirectRole }