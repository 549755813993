import {initialState} from './slice'
import {RootState} from 'setup/redux/RootReducer'
import {createSelector} from '@reduxjs/toolkit'

const selectDashboardState = (state: RootState) => state.dashboard || initialState

export const selectSeriesState = createSelector([selectDashboardState], ({...dashboard}) => [
  {...dashboard.stateDB.seriesCash}, {...dashboard.stateDB.seriesRent}
])

export const selectLegendsState = createSelector([selectDashboardState], ({...dashboard}) => [...dashboard.stateDB.labels])
export const selectAssistantDB = createSelector([selectDashboardState], ({...dashboard}) => [...dashboard.assistantDB])
export const selectCommunityDB = createSelector([selectDashboardState], ({...dashboard}) => dashboard.communityDB)
export const selectTopDB = createSelector([selectDashboardState], ({...dashboard}) => dashboard.topDB)
