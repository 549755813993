const {toast} = require('react-toastify')

const showToastr = (content: string, type: 'success' | 'fail' | 'warning') => {
    switch (type) {
        case 'success':
            toast.success(content, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: 0,
                theme: "colored"
            });
            break;
            
        case 'fail':
            toast.error(content, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: 0,
                theme: "colored"
            });
            break;

        case 'warning':
            toast.warn(content, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: 0,
                theme: "colored"
            });
            break;
    }
}

export {showToastr}