import { IResponsesCommon, IResponsesUpdateCommon } from 'app/types/commonTypes';
import axios, { AxiosResponse } from 'axios'
import { IPost } from 'app/modules/management/communities/post/redux/slice';

const POST_URL = `posts`

export const getPostAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<IPost>>> => {
  let requestURL = `${POST_URL}?sortedBy=desc&orderBy=created_at&withCount=reports`;
  
  return axios.get(requestURL, {
    params
  })
};

export const getDetailPost = (uuid: string, params: any): Promise<AxiosResponse<IResponsesCommon<IPost>>> => {
  let requestURL = `${POST_URL}/${uuid}`;
  
  return axios.get(requestURL, {
    params
  });
};

export const removePostAPI = (uuids: any): Promise<AxiosResponse<IResponsesCommon<IPost>>> => {
  let requestURL = `${POST_URL}/delete-multiple`;
  return axios.post(requestURL, {uuid: uuids});
};

export const createPostAPI = (data: any): Promise<AxiosResponse<IResponsesUpdateCommon<IPost>>> => {
  return axios.post(POST_URL, data)
};

export const updatePostAPI = (id: string, data: any): Promise<AxiosResponse<IResponsesUpdateCommon<IPost>>> => {
  let requestURL = `${POST_URL}/${id}`;
  
  return axios.put(requestURL, data)
};
