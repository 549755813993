import { call, put, takeLatest} from 'redux-saga/effects'
import {getListCoupon, getListCouponSuccess, getDetailCoupon, getDetailCouponSuccess, getStatsticalCoupon, getStatsticalCouponSuccess} from './slice'
import {setLoading} from '../../../../../redux/loadingSlice'
import { getCouponAPI, getDetailCouponAPI, getStatsticalAPI } from 'app/services/couponService'

export function* couponSaga() {
  //list
  yield takeLatest(getListCoupon.type, function* eventRequested(action: any) {
    const { data } = yield call(getCouponAPI, action.payload);
    yield put(getListCouponSuccess(data));
  })

  //detail
  yield takeLatest(getDetailCoupon.type, function* eventRequested(action: any) {
    const { uuid } = action.payload;
    const { data } = yield call(getDetailCouponAPI, uuid);

    yield put(getDetailCouponSuccess(data));
  })

  //statstical
  yield takeLatest(getStatsticalCoupon.type, function* eventRequested(action: any) {
    const { data } = yield call(getStatsticalAPI);
    
    yield put(getStatsticalCouponSuccess(data.data));
  })
}