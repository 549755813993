import {call, put, takeLatest} from 'redux-saga/effects'
import { getListQuotesRentLease, getListQuoteRentLeaseSuccess, getRentQuoteLeaseDetail, getRentQuoteLeaseDetailSuccess } from './slice'
import {getQuotesRentLeaseAPI} from '../../../../../../services/quoteRentLeaseService'
import {getRenleaseDetailAPI} from '../../../../../../services/renLeaseService'

export function* quotesRenLeaseSaga() {
  yield takeLatest(getListQuotesRentLease.type, function* getListRentLeaseRequested(action: any) {
    try {
      const { data } = yield call(getQuotesRentLeaseAPI, action.payload)
      yield put(getListQuoteRentLeaseSuccess(data))
    } catch (error: unknown) {

    }
  })
  yield takeLatest(getRentQuoteLeaseDetail.type, function* renLeaseRequested(action: any) {
    try {
      const { data } = yield call(getRenleaseDetailAPI, action.payload.uuid);
      yield put(getRentQuoteLeaseDetailSuccess(data.data));
    } catch (error: unknown) {
    }
  })
}