import { showToastr } from 'app/helpers/toastr';
import { getExportAPI } from 'app/services/exportSevice';
import { call, put, takeLatest} from 'redux-saga/effects'
import { getExports, getExportsSuccess } from './slice'

export function* exportSaga() {
  yield takeLatest(getExports.type, function* exportRequested(action: any) {    
    try {
      const { data } = yield call(getExportAPI, action.payload);    
      yield put(getExportsSuccess(data));
    } catch (error: unknown) {
      yield showToastr(error as string, 'fail')
    }
  })
}