import {createSlice, current, PayloadAction} from '@reduxjs/toolkit'
import {IResponsesCommon} from '../../../../../../types/commonTypes'
import {IRenLease} from '../../../../../query/database/RenLease/redux/slice'


export interface IQuotesDBRenLease {
  rents: IResponsesCommon<any>,
  renLeaseDetail?: IRenLease
}

const initialState: IQuotesDBRenLease = {
  rents: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as any
    }
  } as IResponsesCommon<any>
}

const quotesDBRenLeaseSlice = createSlice({
  name: 'quotes-db-renlease',
  initialState,
  reducers: {
    getListQuotesRentLease(state, action: PayloadAction<any>) {},
    getListQuoteRentLeaseSuccess: (state, action: PayloadAction<IResponsesCommon<any>>) => {
      state.rents = action.payload
    },
    updateQuoteRenLease: (state, action: PayloadAction<IRenLease>) => {
      const tempState = [...current(state).rents.data.data]
      const tempIndex = tempState.findIndex((el) => el.uuid === action.payload.uuid)
      if (tempIndex >= 0) {
        state.rents.data.data[tempIndex] = action.payload
      }
    },
    getRentQuoteLeaseDetail(state, action: PayloadAction<{uuid: string}>) {},
    getRentQuoteLeaseDetailSuccess: (state, action: PayloadAction<IRenLease>) => {
      state.renLeaseDetail = action.payload
    },
  }
})

export const { getListQuotesRentLease, getListQuoteRentLeaseSuccess, getRentQuoteLeaseDetail, getRentQuoteLeaseDetailSuccess, updateQuoteRenLease } = quotesDBRenLeaseSlice.actions

export default quotesDBRenLeaseSlice.reducer