import {IReport} from 'app/modules/management/communities/report/redux/slice'
import {IResponsesCommon, IResponsesUpdateCommon} from 'app/types/commonTypes'
import axios, {AxiosResponse} from 'axios'

const REPORT_URL = `reports`

const ORTHER_PARAM = {
  with: 'reportable',
}

export const getReportAPI = (
  params: any,
  type: 'post' | 'comment' | 'user'
): Promise<AxiosResponse<IResponsesCommon<IReport>>> => {
  let requestURL = `${REPORT_URL}?sortedBy=desc&orderBy=created_at`;
  return axios.get(requestURL, {
    params: {...params, type},
  })
}

export const getReportDetailAPI = (
  id: string
): Promise<AxiosResponse<IResponsesCommon<IReport>>> => {
  return axios.get(`${REPORT_URL}/${id}`)
}

export const createReportAPI = (
  data: any
): Promise<AxiosResponse<IResponsesUpdateCommon<IReport>>> => {
  return axios.post(REPORT_URL, data)
}

export const updateReportAPI = (
  id: string,
  type: 'post' | 'comment',
  data: any
): Promise<AxiosResponse<IResponsesUpdateCommon<IReport>>> => {
  let requestURL = `${REPORT_URL}/${id}?type=${type}`
  return axios.put(requestURL, data)
}

export const deleteReportAPI = (
  data: string[]
): Promise<AxiosResponse<IResponsesUpdateCommon<IReport>>> => {
  let requestURL = `${REPORT_URL}/delete-multiple`
  return axios.post(requestURL, {uuid: data})
}
