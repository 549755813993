import {createSlice, current, PayloadAction} from '@reduxjs/toolkit'
import { IResponsesCommon } from 'app/types/commonTypes'

export interface IVideo {
  uuid: string,
  name: string,
  description: string,
  mime_type: string,
  size: number,
  path: string,
  url: string,
  created_at: string,
  updated_at: string,
}
export interface ContainerVideoState {
  video: IResponsesCommon<IVideo>
}

export const initialState: ContainerVideoState = {
  video: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as IVideo[]
    }
  } as IResponsesCommon<IVideo>
}

const videoSlice = createSlice({
  name: 'video-state',
  initialState,
  reducers: {
    getVideoManage(state, action: PayloadAction<any>) {},
    getVideoManageSuccess: (state, action: PayloadAction<IResponsesCommon<IVideo>>) => {
      state.video = action.payload
    },
    updateVideoManage: (state, action: PayloadAction<IVideo>) => {      
      const tempState = [...current(state).video.data.data];
      const tempIndex = tempState.findIndex((el) => el.uuid === action.payload.uuid)      
      if(tempIndex >= 0) {
        state.video.data.data[tempIndex] = action.payload
      }
    }
  }
})

export const { getVideoManage, getVideoManageSuccess, updateVideoManage } = videoSlice.actions

export default videoSlice.reducer
