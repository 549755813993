import axios, {AxiosResponse} from 'axios'

const RENLEASE_URL = `rents`


export const getQuotesRentLeaseAPI = (params: any): any => {
  return axios.get(RENLEASE_URL, {
    params: {
      ...params,
      orderBy: 'created_at',
      sortedBy: 'desc',
      with: 'manufacturer;carType;carLevel;engineType;user;options;insideColor;outsideColor;attachments;assistant;evaluationResults',
      types: 1
    },
  })
}

export const deleteQuotesRentLeaseAPI = (uuid: []): any => {
  return axios.post(`${RENLEASE_URL}/delete-multiple`, {
    uuid,
  })
}

export const updateQuotesRentLeaseAPI = (uuid: string, data: any): any => {
  return axios.put(`${RENLEASE_URL}/${uuid}`, data)
}

export const createQuotesRentLeaseAPI = (data: any): any => {
  return axios.post(RENLEASE_URL, {
    ...data,
    type: 1
  })
}