import { IResponsesCommon } from 'app/types/commonTypes';
import axios, { AxiosResponse } from 'axios'
import { ICarFavorite, IRank } from '../modules/management/other/favorite-car/redux/slice'

const CAR_FAVORITE_URL = `car-favorites`

export const getRentAPI = (params: any): Promise<AxiosResponse<IResponsesCommon<ICarFavorite>>> => {
  let requestURL = `${CAR_FAVORITE_URL}`;
  return axios.get(requestURL, {
    params
  })
};

export const createFavoriteAPI = (data: any): Promise<AxiosResponse<IResponsesCommon<ICarFavorite>>> => {
  let requestURL = `${CAR_FAVORITE_URL}`;
  return axios.post(requestURL, data)
};

export const updateFavoriteAPI = (id: string, data: any): Promise<AxiosResponse<IResponsesCommon<ICarFavorite>>> => {
  let requestURL = `${CAR_FAVORITE_URL}/${id}`;
  return axios.put(requestURL, data)
};

export const getDetailRentAPI = (uuid: string): Promise<AxiosResponse<IResponsesCommon<ICarFavorite>>> => {
  let requestURL = `${CAR_FAVORITE_URL}`;

  return axios.get(`${requestURL}/${uuid}`)
};

export const removeRentAPI = (uuids: any): Promise<AxiosResponse<IResponsesCommon<ICarFavorite>>> => {
  let requestURL = `${CAR_FAVORITE_URL}/delete-multiple`;
  return axios.post(requestURL, { uuid: uuids })
};

export const getRankAPI = (params: any): Promise<AxiosResponse<IRank>> => {
  let requestURL = `${CAR_FAVORITE_URL}/rank-list`;
  let result =  axios.get(requestURL, {
    params
  })
  return result;
};

export const updateRankAPI = (data: any): Promise<AxiosResponse<IRank>> => {
  let requestURL = `${CAR_FAVORITE_URL}/update-rank`;
  return axios.post(requestURL, data)
};