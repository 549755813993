import {call, put, takeLatest} from 'redux-saga/effects'
import { getListQuoteInstallmentCashSuccess, getListQuotesInstallmentCash } from './slice'
import {getQuotesInstallmentCashAPI} from '../../../../../../services/quoteInstallmentCashService'

export function* quotesInstallmentCashSaga() {
  yield takeLatest(getListQuotesInstallmentCash.type, function* getListInstallmentCashRequested(action: any) {
    try {
      const { data } = yield call(getQuotesInstallmentCashAPI, action.payload)
      yield put(getListQuoteInstallmentCashSuccess(data))
    } catch (error: unknown) {

    }
  })
}