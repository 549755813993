/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useAppDispatch, useAppSelector} from '../../../hooks'
import { getInfoTopDBDashboard } from '../redux/slice'
import {selectTopDB} from '../redux/selector'
import find from 'lodash/find'
import {CUSTOMER_TYPES_LIST, RENT_PATH, TYPES_LIST} from '../../../contants/renst'
import useLavavelEcho from '../../../hooks/useLavavelEcho'
import { setInfoTopDBDashboard } from 'app/pages/dashboard/redux/slice'

type Props = {
  className: string
}

const formatter = (cell: any, list: any) => {
  const formatted = find(list, (item) => item.value == cell)
  if(formatted) return formatted.label
  else return '-'
}

const RealTimeDBStatus: React.FC<Props> = ({className}) => {
  const dispatch = useAppDispatch()
  const [selectRent, setSelectRent] = useState(0)
  const [selectPath, setSelectPath] = useState('0')
  const topDB = useAppSelector(selectTopDB)

  useLavavelEcho('.rent.updated', 'rent-update', (data: any) => {
    const { type, path } = data
    if(type == selectRent && path == selectPath)
      dispatch(setInfoTopDBDashboard(data))
  })

  useEffect(() => {
    dispatch(getInfoTopDBDashboard({
      type: selectRent,
      path: selectPath,
    }))
  }, [selectRent, selectPath])


  return (
    <div className={`card box-shadow-primary scroll-primary ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-1 border-bottom border-gray-680'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fs-3'>실시간 DB 현황</span>
        </h3>
        <div className='card-toolbar ms-auto me-2'>
          <select
            name='status'
            data-control='select2'
            data-hide-search='true'
            className='form-select form-select-white form-select-sm fs-3 w-fit-content border-0'
            value={selectRent}
            onChange={(e) => setSelectRent(parseInt(e.target.value))}
          >
            <option value="0">구매유형</option>
            {
              TYPES_LIST.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)
            }
          </select>
          
        </div>
        <div className='card-toolbar'>
          <select
            name='status'
            data-control='select2'
            data-hide-search='true'
            className='form-select form-select-white form-select-sm fs-3 w-fit-content border-0'
            value={selectPath}
            onChange={(e) => setSelectPath(e.target.value)}
          >
            <option value="0">유입경로</option>
            {
              RENT_PATH.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)
            }
          </select>
          
        </div>
      </div>
      {/* end::Header */}
      <div className='card-body py-3 dashboard-table-wrapper max-height-230'>
        <table className='w-100 dashboard-table'>
          <thead className='bg-gray-780 p-5 text-white'>
            <tr>
              <td>구매유형</td>
              <td>유입경로</td>
              <td>차종</td>
              <td>고객명</td>
              <td>고객유형</td>
            </tr>
          </thead>
          <tbody>
            {topDB.map((item: any, idx: any) => (
              <tr key={idx}>
                <td>{formatter(item.type, TYPES_LIST)}</td>
                <td>{formatter(item.path, [])}</td>
                <td>{item.car_type}</td>
                <td>{item.customer_name}</td>
                <td>{formatter(item.customer_type, CUSTOMER_TYPES_LIST)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export {RealTimeDBStatus}
