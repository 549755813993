import {ASSISTANT_ROLE, DEALER_ROLE, SUPPER_ADMIN, USER_NORMAL_ROLE} from 'app/contants'
import {authorizationStrategy, handleRedirectRole} from 'app/helpers'
import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

const QueryDatabasePage = lazy(() => import('../modules/query/database/QueryDatabasePage'))
const QueryNotificationPage = lazy(
  () => import('../modules/query/notification/QueryNotificationPage')
)
const QueryNotificationDetailPage = lazy(
  () => import('../modules/query/notification/DetailQueryNotification')
)

const DatabasePage = lazy(() => import('../modules/management/quotes/database/DatabasePage'))
const ExportPage = lazy(() => import('../modules/management/quotes/export/ExportPage'))
const ComparePage = lazy(() => import('../modules/management/quotes/compare/ComparePage'))
const UsersPage = lazy(() => import('../modules/management/users/UsersPage'))

const PostPage = lazy(() => import('../modules/management/communities/post/PostPage'))
const ReportPage = lazy(() => import('../modules/management/communities/report/ReportPage'))

const EventPage = lazy(() => import('../modules/management/events/event/EventPage'))
const PointPage = lazy(() => import('../modules/management/events/point/PointPage'))
const CouponPage = lazy(() => import('../modules/management/events/coupon/CouponPage'))

const AlarmPage = lazy(() => import('../modules/management/alarm/AlarmPage'))
const NoticePage = lazy(() => import('../modules/management/notice/NoticePage'))

const FavoriteCarPage = lazy(
  () => import('../modules/management/other/favorite-car/FavoriteCarPage')
)
const FAQPage = lazy(() => import('../modules/management/other/faq/FAQPage'))
const YoutubePage = lazy(() => import('../modules/management/other/youtube/YoutubePage'))

export const ROUTES = [
  // Dashboard
  {
    path: '/dashboard',
    component: DashboardWrapper,
    roles: [SUPPER_ADMIN],
    title: '대시보드',
    menuParent: true,
  },
  // Truy van
  {
    path: '/query/database',
    component: QueryDatabasePage,
    roles: [SUPPER_ADMIN, ASSISTANT_ROLE, DEALER_ROLE],
  },
  {
    path: '/query/database/installment-cash',
    component: QueryDatabasePage,
    roles: [SUPPER_ADMIN, ASSISTANT_ROLE, DEALER_ROLE],
  },
  {
    path: '/query/notifications',
    component: QueryNotificationPage,
    roles: [SUPPER_ADMIN, ASSISTANT_ROLE, DEALER_ROLE, USER_NORMAL_ROLE],
    exact: true,
  },
  {
    path: '/query/notifications/:id',
    component: QueryNotificationDetailPage,
    roles: [SUPPER_ADMIN, ASSISTANT_ROLE, DEALER_ROLE, USER_NORMAL_ROLE],
  },
  // Quan ly bao gia
  {
    path: '/manager/quote/databases',
    component: DatabasePage,
    roles: [SUPPER_ADMIN],
  },
  {
    path: '/manager/quote/exports',
    component: ExportPage,
    roles: [SUPPER_ADMIN],
  },
  {
    path: '/manager/quote/compare-quotes',
    component: ComparePage,
    roles: [SUPPER_ADMIN],
  },
  // Quan ly hoi vien
  {
    path: '/manager/users',
    component: UsersPage,
    roles: [SUPPER_ADMIN],
  },
  // Quan ly community
  {
    path: '/manager/community/posts',
    component: PostPage,
    roles: [SUPPER_ADMIN],
  },
  {
    path: '/manager/community/reports',
    component: ReportPage,
    roles: [SUPPER_ADMIN],
  },
  // Quan ly event
  {
    path: '/manager/events/events',
    component: EventPage,
    roles: [SUPPER_ADMIN],
  },
  {
    path: '/manager/events/event-point',
    component: PointPage,
    roles: [SUPPER_ADMIN],
  },
  {
    path: '/manager/events/event-coupon',
    component: CouponPage,
    roles: [SUPPER_ADMIN],
  },
  // Quan ly alarm
  {
    path: '/manager/alarm',
    component: AlarmPage,
    roles: [SUPPER_ADMIN],
  },
  // Quan ly thong bao chung
  {
    path: '/manager/notice',
    component: NoticePage,
    roles: [SUPPER_ADMIN],
  },
  // Quan ly khac
  {
    path: '/manager',
    component: FavoriteCarPage,
    roles: [SUPPER_ADMIN],
    exact: true,
  },
  {
    path: '/manager/other',
    component: FavoriteCarPage,
    roles: [SUPPER_ADMIN],
    exact: true,
  },
  {
    path: '/manager/other/favorite-car',
    component: FavoriteCarPage,
    roles: [SUPPER_ADMIN],
  },
  {
    path: '/manager/other/youtube',
    component: YoutubePage,
    roles: [SUPPER_ADMIN],
  },
  {
    path: '/manager/other/faq',
    component: FAQPage,
    roles: [SUPPER_ADMIN],
  },
]

export function PrivateRoutes({ROLE}: {ROLE: number}) {
  return (
    <Suspense fallback={<></>}>
      <Switch>
        {ROUTES.map((route) => (
          <PermissionsProvider
            path={route.path}
            exact={route.exact}
            component={route.component}
            role={ROLE}
            requirements={route.roles}
          />
        ))}

        <Redirect from='/auth' to={handleRedirectRole(ROLE)} />
        <Redirect exact from='/' to={handleRedirectRole(ROLE)} />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}

type PermissionsProviderType = {
  path: string
  component: React.LazyExoticComponent<React.FC<{}>> | React.FC<{}>
  role: number
  exact?: boolean
  requirements: number[]
}

const PermissionsProvider: React.FC<PermissionsProviderType> = ({
  exact = false,
  path,
  component,
  role,
  requirements = [],
}) => {
  return (
    <>
      {authorizationStrategy(role, requirements) ? (
        <Route path={path} component={component} exact={exact} />
      ) : (
        <Redirect to='error/404' />
      )}
    </>
  )
}
