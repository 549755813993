import {IAlarm} from 'app/modules/management/alarm/redux/slice'
import {IResponsesCommon, IResponsesUpdateCommon} from 'app/types/commonTypes'
import axios, {AxiosResponse} from 'axios'

const ALARM_URL = `alarms`
const MESSAGES_URL = `messages`

// TYPE
//   1 KAKAOTALK
//   2 PUSH
//   3 CHAT BOT

export const getAlarmAPI = (
  params: any,
  type: 1 | 2 | 3
): Promise<AxiosResponse<IResponsesCommon<IAlarm>>> => {
  let withs = `manufacturer;carLevel;assistant`
  if (type === 3) {
    withs += ';room.messages'
  }
  return axios.get(ALARM_URL, {
    params: {
      ...params,
      alarm_type: type,
      orderBy: 'created_at',
      sortedBy: 'desc',
      with: withs,
    },
  })
}

export const getAlarmDetailAPI = (id: string): Promise<AxiosResponse<IAlarm>> => {
  return axios.get(`${ALARM_URL}/${id}`, {})
}

export const deleteAlarmAPI = (
  data: string[]
): Promise<AxiosResponse<IResponsesUpdateCommon<IAlarm>>> => {
  let requestURL = `${ALARM_URL}/delete-multiple`
  return axios.post(requestURL, {uuid: data})
}

export const updateAlarmAPI = (
  id: string,
  data: any
): Promise<AxiosResponse<IResponsesUpdateCommon<IAlarm>>> => {
  let requestURL = `${ALARM_URL}/${id}`
  return axios.put(requestURL, data)
}

export const createAlarmAPI = (
  data: any
): Promise<AxiosResponse<IResponsesUpdateCommon<IAlarm>>> => {
  return axios.post(ALARM_URL, data)
}

export const getMessagesAlarmAPI = (
  id: string
): Promise<AxiosResponse<IResponsesCommon<IAlarm>>> => {
  let requestURL = `${ALARM_URL}/${id}/alarm`
  return axios.get(requestURL, {
    params: {
      with: 'room.messages;manufacturer;carLevel;assistant',
    },
  })
}
