import { formatCurrencyValue } from "app/helpers/currency/formatCurrency"

const CUSTOMER_TYPES = {
  // LOAI KHACH HANG
  PERSONAL: 1, // Cá nhân
  PRIVATE_COMPANY: 2, // Doanh nghiệp cá nhân
  LEGAL_ENTITY: 3, //Doanh nghiệp pháp nhân
}

const CUSTOMER_TYPES_LIST = [
  {
    label: '개인',
    value: CUSTOMER_TYPES.PERSONAL,
  },
  {
    label: '개인사업자',
    value: CUSTOMER_TYPES.PRIVATE_COMPANY,
  },
  {
    label: '법인사업자',
    value: CUSTOMER_TYPES.LEGAL_ENTITY,
  },
]

const CUSTOMER_TYPE_DETAIL = [
  {
    key: 1010,
    label: '4대보험 직장인'
  },
  {
    key: 1011,
    label: '프리랜서'
  },
  {
    key: 1012,
    label: '현재 직업 없음'
  },
  {
    key: 1021,
    label: '1년 이상'
  },
  {
    key: 1022,
    label: '1년 미만'
  }
]

const PRE_MONEY = {
  // HÌNH THUC MUA
  DEPOSIT: 1, // DAT COC
  PRE_PAY: 2, // TRA TRUOC
}

const PRE_MONEY_LIST = [
  {
    label: '보증금',
    value: PRE_MONEY.DEPOSIT,
  },
  {
    label: '선수금',
    value: PRE_MONEY.PRE_PAY,
  },
]

const STATUS_PROCESSING = {
  // PROCESSING: 1, // DANG XU LY
  SCHEDULING: 1, // DANG LEN LICH TU VAN
  CONSULTING: 2, // DANG_TU_VAN
  COMPLETE: 3, // HOAN_THANH_TU_VAN
  COMPLETE_CONTRACT: 4, // HOAN_THANH_HOP_DONG
  COMPLETE_HANDOVER: 5, // HOAN_THANH_BAN_GIAO
  CANCLE: 9
}

const STATUS_PROCESSING_LIST = [
  // {
  //   label: '처리 중',
  //   value: STATUS_PROCESSING.PROCESSING,
  // },
  {
    label: '상담예정',
    value: STATUS_PROCESSING.SCHEDULING,
  },
  {
    label: '상담중',
    value: STATUS_PROCESSING.CONSULTING,
  },
  {
    label: '상담완료',
    value: STATUS_PROCESSING.COMPLETE,
  },
  {
    label: '계약완료',
    value: STATUS_PROCESSING.COMPLETE_CONTRACT,
  },
  {
    label: '인도완료',
    value: STATUS_PROCESSING.COMPLETE_HANDOVER,
  },
  {
    label: '상담취소',
    value: STATUS_PROCESSING.CANCLE,
  },
]

const TYPES = {
  LONG_RENT: 1, // THUE DAI HAN
  INSTANT_STOCK: 2, // XUAT_KHO_TUC_THI
  AUTO: 3, // AUTO
  AUTO2: 4,
  DEPOSIT: 5, //TRA GOP/TIEN MAT
}

const TYPES_LIST = [
  {
    label: '장기렌트',
    value: TYPES.LONG_RENT,
  },
  {
    label: '장기렌트(즉출)',
    value: TYPES.INSTANT_STOCK,
  },
  {
    label: '오토리스',
    value: TYPES.AUTO,
  },
  {
    label: '오토리스(즉출)',
    value: TYPES.AUTO2,
  },
  {
    label: '할부/현금',
    value: TYPES.DEPOSIT,
  },
]

const EMPLOYEE_INSURANCE = {
  // BAO HIEM NHAN VIEN
  REGISTERED: 1, // Đã đăng ký
  UNREGISTERED: 0, // Chưa đăng ký
}

const EMPLOYEE_INSURANCE_LIST = [
  {
    label: '가입',
    value: EMPLOYEE_INSURANCE.REGISTERED,
  },
  {
    label: '미가입',
    value: EMPLOYEE_INSURANCE.UNREGISTERED,
  },
]

const CONTRACT_TIME = {
  // THOI GIAN HOP DONG
  MONTHS_36: 36,
  MONTHS_48: 48,
  MONTHS_60: 60,
}

const CONTRACT_TIME_LIST = [
  {
    label: '36개월',
    value: CONTRACT_TIME.MONTHS_36,
  },
  {
    label: '48개월',
    value: CONTRACT_TIME.MONTHS_48,
  },
  {
    label: '60개월',
    value: CONTRACT_TIME.MONTHS_60,
  },
]

const HANDOVER_AREA = {
  // DIA DIEM TEN RIENG HAN QUOC
  NORTHERN_GYEONGGI: '경기도 북부',
  SOUTH_GYEONGGI: '경기도 남부',
  INCHEON_METROPOLITAN_CITY: '인천광역시',
  GANGWON_DO: '충청도',
  CHUNGCHEONG_DO: '강원도',
  GANGWON_DO_YEONGSEO: '강원도 영서',
  YEONGDONG_GANGWON_DO: '강원도 영동',
  JEOLLABUK_DO: '전라북도',
  JEOLLANAM_DO: '전라남도',
  GYEONGSANGBUK_DO: '경상북도',
  GYEONGSANGNAM_DO: '경상남도',
  JEJU_ISLAND: '제주도',
  SEOUL: '서울'
}

const HANDOVER_AREA_LIST = [
  {
    label: '서울',
    value: HANDOVER_AREA.SEOUL,
  },
  {
    label: '경기 북부',
    value: HANDOVER_AREA.NORTHERN_GYEONGGI,
  },
  {
    label: '경기 남부',
    value: HANDOVER_AREA.SOUTH_GYEONGGI,
  },
  {
    label: '인천광역시',
    value: HANDOVER_AREA.INCHEON_METROPOLITAN_CITY,
  },
  {
    label: '충청도',
    value: HANDOVER_AREA.GANGWON_DO,
  },
  {
    label: '강원도',
    value: HANDOVER_AREA.CHUNGCHEONG_DO,
  },
  {
    label: '강원도 영서',
    value: HANDOVER_AREA.GANGWON_DO_YEONGSEO,
  },
  {
    label: '강원도 영동',
    value: HANDOVER_AREA.YEONGDONG_GANGWON_DO,
  },
  {
    label: '전라북도',
    value: HANDOVER_AREA.JEOLLABUK_DO,
  },
  {
    label: '전라남도',
    value: HANDOVER_AREA.JEOLLANAM_DO,
  },
  {
    label: '경상북도',
    value: HANDOVER_AREA.GYEONGSANGBUK_DO,
  },
  {
    label: '경상남도',
    value: HANDOVER_AREA.GYEONGSANGNAM_DO,
  },
  {
    label: '제주도',
    value: HANDOVER_AREA.JEJU_ISLAND,
  },
]

const INSURANCE_AGE_LIST = [
  {
    label: '만 21세 이상',
    value: 21,
  },
  {
    label: '만 26세 이상',
    value: 26,
  },
]

const MAINTENANCE_TYPE = {
  //- Sản phẩm bảo dưỡng: Tự bảo dưỡng/Bổ sung bảo dưỡng
  SELF_MAINTENANCE: 1, //Tự bảo dưỡng
  MAINTENANCE: 2, // Bổ sung bảo dưỡng
}
const MAINTENANCE_TYPE_LIST = [
  {
    label: '자가정비',
    value: MAINTENANCE_TYPE.SELF_MAINTENANCE,
  },
  {
    label: '정비추가',
    value: MAINTENANCE_TYPE.MAINTENANCE,
  },
]

const OPTIONAL_AT_EXPIRATION = {
  SELECTION_TYPE: '선택형', // Lựa chọn
  TAKEOVER_TYPE: '인수형', // Nhận bàn giao
  RETURN_TYPE: '반납형', // Trả lại
}

const OPTIONAL_AT_EXPIRATION_LIST = [
  {
    label: '인수형',
    value: OPTIONAL_AT_EXPIRATION.TAKEOVER_TYPE,
  },
  {
    label: '반납형',
    value: OPTIONAL_AT_EXPIRATION.RETURN_TYPE,
  },
  {
    label: '선택형',
    value: OPTIONAL_AT_EXPIRATION.SELECTION_TYPE,
  }
]

const DEPOSITS = {
  NONE_0: 1,
  NONE_50: 2,
}

const DEPOSITS_LIST = [
  {
    label: '0%(0원)',
    value: DEPOSITS.NONE_0,
  },
  {
    label: '없음/0-50%',
    value: DEPOSITS.NONE_50,
  },
]

const PREPAYMENT = {
  NONE_0: 1,
  NONE_50: 2,
}

const PREPAYMENT_LIST = [
  {
    label: '0%(0원)',
    value: PREPAYMENT.NONE_0,
  },
  {
    label: '30%(9,000,000원)',
    value: PREPAYMENT.NONE_50,
  },
]

const SELECTED_WHEN_EXPIRED = {
  // Hạng mục chọn khi hết hạn
  SELECT: 1,
  RECEIVE: 2,
  RETURN: 3,
}

const SELECTED_WHEN_EXPIRED_LIST = [
  {
    label: '선택형',
    value: SELECTED_WHEN_EXPIRED.SELECT,
  },
  {
    label: '인수형',
    value: SELECTED_WHEN_EXPIRED.RECEIVE,
  },
  {
    label: '반납형',
    value: SELECTED_WHEN_EXPIRED.RETURN,
  },
]

const DISTANCE_IN_YEAR = [
  {
    label:'1만 Km',
    value: 1
  },
  {
    label:'1.5만 Km',
    value: 2
  },
  {
    label:'2만 Km',
    value: 3
  },
  {
    label:'2.5만 Km',
    value: 4
  },
  {
    label:'3만 Km',
    value: 5
  },
  {
    label:'4만 Km',
    value: 6
  },
  {
    label:'5만 Km',
    value: 7
  },
  {
    label:'무제한',
    value: 8
  },
];

const CONSULT_TYPES = {
  tel: 1,
  kakao: 2
}

const CONSULT_TYPES_LIST = [
  {
    label: '전화',
    value: CONSULT_TYPES.tel,
  },
  {
    label: '카카오톡',
    value: CONSULT_TYPES.kakao,
  },
]


// khoảng cách chạy hàng năm
const GET_LIST_DISTANCE_IN_YEARS = (isAutoLease = false) =>
  isAutoLease
    ? new Array(3)
        .fill(0)
        .map((_, idx) => ({
          label: `${(idx + 1) * 10000 + 10000}km`,
          value: (idx + 1) * 10000 + 10000,
        }))
    : new Array(9)
        .fill(0)
        .map((_, idx) => ({label: `${(idx + 1) * 5000 + 5000}km`, value: (idx + 1) * 5000 + 5000}))
        .filter((i) => i.value !== 35000 && i.value !== 45000)

// tien đặt cọc và tiền trả trước
const DOWN_PAYMENT = (consumableFee: number, initNumber = 0) => {
  const count = 51;
  const arr = new Array(count-initNumber).fill(0).map((_, idx) => ({
    label: `${idx}% (${formatCurrencyValue((consumableFee * (idx / 100)))}원)`,
    value: idx,
  })).filter((i) => i.value % 10 == 0);
  arr.push({label:'직접입력', value:1000});
  return arr;
}

const RENT_PATH = [
  {
    label: '렌트/리스',
    value: 'rent/lease',
  },
  {
    label: '즉시 출고',
    value: 'immediate release',
  },
  {
    label: '할부/현금',
    value: 'cash',
  },
]

export {
  CUSTOMER_TYPES,
  CUSTOMER_TYPES_LIST,
  PRE_MONEY,
  PRE_MONEY_LIST,
  STATUS_PROCESSING,
  STATUS_PROCESSING_LIST,
  TYPES,
  TYPES_LIST,
  EMPLOYEE_INSURANCE,
  EMPLOYEE_INSURANCE_LIST,
  CONTRACT_TIME,
  CONTRACT_TIME_LIST,
  HANDOVER_AREA,
  HANDOVER_AREA_LIST,
  INSURANCE_AGE_LIST,
  MAINTENANCE_TYPE,
  MAINTENANCE_TYPE_LIST,
  OPTIONAL_AT_EXPIRATION,
  OPTIONAL_AT_EXPIRATION_LIST,
  DEPOSITS,
  DEPOSITS_LIST,
  PREPAYMENT_LIST,
  SELECTED_WHEN_EXPIRED_LIST,
  DISTANCE_IN_YEAR,
  GET_LIST_DISTANCE_IN_YEARS,
  DOWN_PAYMENT,
  RENT_PATH,
  CUSTOMER_TYPE_DETAIL,
  CONSULT_TYPES,
  CONSULT_TYPES_LIST
}
