import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { IResponsesCommon } from 'app/types/commonTypes'

export interface IPost {
  uuid: string,
  id:number,
  user_id: number,
  title: string,
  excerpt: string,
  content: string,
  status: number,
  is_hot: boolean,
  is_new: boolean,
  is_review: boolean,
  is_notice: boolean,
  comments_count: number,
  reactions_count: number,
  is_like: boolean,
  image: object,
  created_at: string,
  updated_at: string,
}
export interface ContainerFAQState {
  posts: IResponsesCommon<IPost>
}

export const initialState: ContainerFAQState = {
  posts: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as IPost[]
    }
  } as IResponsesCommon<IPost>
}

const postSlice = createSlice({
  name: 'post-state',
  initialState,
  reducers: {
    getPostsList(state, action: PayloadAction<any>) {},
    getPostsListSuccess: (state, action: PayloadAction<IResponsesCommon<IPost>>) => {
      state.posts = action.payload
    }
  }
})

export const { 
    getPostsList,
    getPostsListSuccess,
} = postSlice.actions

export default postSlice.reducer
