import { IUser } from 'app/modules/management/users/users/redux/slice';
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IResponsesCommon} from 'app/types/commonTypes'

export interface IMessage {
  uuid: string
  user_id: number
  room_id: number
  content: {
    text?: string
  }
  type: number
  created_at: string
  updated_at: string
}
export interface IAlarm {
  id: number
  uuid: string
  user_id: number
  alarm_type: number
  send_status: number
  send_type: number
  send_date: string
  send_result: number
  title: string
  message: string
  is_repeat: boolean
  repeat_type: number
  repeat_date: string
  user: IUser
  created_at: string
  is_event_on: number
}
export interface ContainerAlarmtate {
  alarms: IResponsesCommon<IAlarm>
  messages: IMessage[],
  accessTokenKakao: string
}

export const initialState: ContainerAlarmtate = {
  alarms: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as IAlarm[],
    },
  } as IResponsesCommon<IAlarm>,
  messages: [],
  accessTokenKakao: ''
}

const alarmSlice = createSlice({
  name: 'alarm-state',
  initialState,
  reducers: {
    getAlarms(state, action: PayloadAction<{ type: number, params: any}>) {},
    getAlarmsSuccess: (state, action: PayloadAction<IResponsesCommon<IAlarm>>) => {
      state.alarms = action.payload
    },
    getMessagesAlarms(state, action: PayloadAction<string>) {},  
    getMessagesAlarmsSuccess: (state, action: PayloadAction<IMessage[]>) => {
      state.messages = action.payload
    },
    setAccessTokenKakao: (state, action: PayloadAction<string>) => {
      state.accessTokenKakao = action.payload
    }
  },
})

export const {
  getAlarms,
  getAlarmsSuccess,
  getMessagesAlarms,
  getMessagesAlarmsSuccess,
  setAccessTokenKakao
} = alarmSlice.actions

export default alarmSlice.reducer
