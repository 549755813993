import Swal from 'sweetalert2'
import '../../../_metronic/assets/sass/layout/_custom_popup.scss'

const defaultOptions = {
  confirmButtonText: 'Save',
  denyButtonText: `Don't save`,
  confirmButtonColor: '#286DD1',
  cancelButtonColor: '#5D5D5D',
}

const alertPopup = (title: string, text: string) => {
  return Swal.fire({
    title,
    text,
    showCloseButton: true,
    confirmButtonText: '확인',
    confirmButtonColor: '#286DD1',
  })
}

const confirmPopup =  (title: string, text: string) => {
  return Swal.fire({
    title,
    text,
    showCloseButton: true,
    showDenyButton: true,
    confirmButtonText: '예',
    denyButtonText: `아니요`,
    confirmButtonColor: '#286DD1',
    denyButtonColor: '#5D5D5D',
  })
}


export { alertPopup, confirmPopup }