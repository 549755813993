/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useLayout} from '../core'

const Footer: FC = () => {
  const {classes} = useLayout()
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer}`}
      >
        {/* begin::Copyright */}
        <div className='text-dark'>
          <p className="font-weight-bold font-size-25 mb-0">차켓(CHAKET)</p>
          <div className="mt-6 footer-info">
            <p>
              <span>회사명 : (주)와이즈모빌리티</span>
              <span>대표자 : 송현근</span>
              <span>개인정보책임자 : 송현근</span>
              <span>사업자등록번호 : 219-88-02051</span>
            </p>
            <p>
              <span>대표전화 : 1800-9149</span>
              <span>이메일 : wisemobility0614@naver.com</span>
              <span>주소 : (18469) 경기도 화성시 동탄기흥로 557, 503호(영천동, 금강펜테리움IT타워)</span>
            </p>
            <p>
              Copyright { new Date().getFullYear() }.  Wisemobility Corp. right reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Footer}
