import {IInstallmentCash} from 'app/modules/query/database/InstallmentCash/redux/slice'
import {IResponsesCommon, IResponsesUpdateCommon} from 'app/types/commonTypes'
import axios, {AxiosResponse} from 'axios'

const INSTALLMENT_CASH = `rents`

export const getInstallmentCashAPI = (
  params: any
): Promise<AxiosResponse<IResponsesCommon<IInstallmentCash>>> => {
  return axios.get(INSTALLMENT_CASH, {
    params: {
      ...params,
      orderBy: 'created_at',
      sortedBy: 'desc',
      types: 5,
      with: 'manufacturer;carType;carLevel;engineType;user;options;insideColor;outsideColor;attachments;memo',
    },
  })
}

export const createInstallmentCashAPI = (
  data: any
): Promise<AxiosResponse<IResponsesUpdateCommon<IInstallmentCash>>> => {
  return axios.post(INSTALLMENT_CASH, data)
}

export const updateInstallmentCashAPI = (
  id: string,
  data: any
): Promise<AxiosResponse<IResponsesUpdateCommon<IInstallmentCash>>> => {
  let requestURL = `${INSTALLMENT_CASH}/${id}`
  return axios.put(requestURL, data)
}

export const deleteInstallmentCashAPI = (
  id: string
): Promise<AxiosResponse<IResponsesUpdateCommon<IInstallmentCash>>> => {
  let requestURL = `${INSTALLMENT_CASH}/${id}`
  return axios.delete(requestURL)
}
