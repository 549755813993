/* eslint-disable jsx-a11y/anchor-is-valid */
import downloadExcel from 'app/helpers/download-excel'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import React, {useEffect} from 'react'
import {selectAssistantDB} from '../redux/selector'
import {getInforAssistantDashboard} from '../redux/slice'

type Props = {
  className: string
}

const tableHeader = [
  {
    text: '이름',
    dataField: 'name',
  },
  {
    text: '직급',
    dataField: 'job_title',
  },
  {
    text: '상담예정 DB',
    dataField: 'today_consulting_scheduled_rents_count',
  },
  {
    text: '상담중 DB',
    dataField: 'today_consulting_rents_count',
  },
  {
    text: '상담완료 DB',
    dataField: 'today_completing_consultation_rents_count',
  },
  {
    text: '계약',
    dataField: 'today_completing_contract_rents_count',
  },
  {
    text: '인도',
    dataField: 'today_completing_handover_rents_count',
  },
  {
    text: '이번달 DB',
    dataField: 'current_month_total_db',
  },
  {
    text: '이번달 계약',
    dataField: 'current_month_completing_contract_rents_count',
  },
  {
    text: '이번달 인도',
    dataField: 'current_month_completing_handover_rents_count',
  },
  {
    text: '총 DB',
    dataField: 'total_db',
  },
  {
    text: '총 계약',
    dataField: 'completing_contract_rents_count',
  },
  {
    text: '총 인도',
    dataField: 'completing_handover_rents_count',
  },
]

const DBAssistant: React.FC<Props> = ({className}) => {
  const dispatch = useAppDispatch()
  const assistantsList = useAppSelector(selectAssistantDB)

  const onClickDownLoadExcel = () => {
    downloadExcel(assistantsList, tableHeader, [], 'Assistant-DB')
  }
  useEffect(() => {
    dispatch(getInforAssistantDashboard())
  }, [dispatch])

  return (
    <div className={`card box-shadow-primary scroll-primary ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-1 border-bottom border-gray-680'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fs-3'>어시스턴트 현황</span>
        </h3>
        <div className='card-toolbar'>
          <h3 className='card-title align-items-start flex-column' onClick={onClickDownLoadExcel}>
            <span className='card-label fs-3 cursor-pointer'>엑셀다운로드</span>
          </h3>
        </div>
      </div>
      {/* end::Header */}
      <div className='card-body py-3 dashboard-table-wrapper max-height-260'>
        <table className='w-100 dashboard-table'>
          <thead className='bg-gray-780 p-5 text-white'>
            <tr>
            {tableHeader.map((item, idx) => (
              <td key={idx}>{item.text}</td>
            ))}
            </tr>
          </thead>
          <tbody>
            {
              assistantsList.map((item, idx) => (
              <tr key={idx}>
                <td className='td-cell-ellipsis w-70'>{item.name}</td>
                <td>{item.job_title}</td>
                {/*
                <td>{item.today_consulting_scheduled_rents_count}</td>
                <td>{item.today_consulting_rents_count}</td>
                <td>{item.today_completing_consultation_rents_count}</td>
                */}
                <td>{item.current_month_consulting_scheduled_rents_count}</td>
                <td>{item.current_month_consulting_rents_count}</td>
                <td>{item.current_month_completing_consultation_rents_count}</td>

                {/* hop dong */}
                <td>{item.today_completing_contract_rents_count}</td>
                {/* ban giao */}
                <td className='border-right-3'>{item.today_completing_handover_rents_count}</td>
                {/* db thang nay */}
                <td className='text-color-blue'>{item.current_month_total_db}</td>
                {/* hop dong thang nay */}
                <td className='text-color-blue'>
                  {item.current_month_completing_contract_rents_count}
                </td>
                {/* ban giao thang này */}
                <td className='border-right-3 text-color-blue'>
                  {item.current_month_completing_handover_rents_count}
                </td>
                {/* tong db */}
                <td className='text-danger'>{item.total_db}</td>
                {/* tong hop dong */}
                <td className='text-danger'>{item.completing_contract_rents_count}</td>
                {/* tong ban giao */}
                <td className='text-danger'>{item.completing_handover_rents_count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export {DBAssistant}
