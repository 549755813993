import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IResponsesCommon } from 'app/types/commonTypes'

export interface ICar {
  uuid: string,
  title: string,
  fuel_efficiency: string,
  fuel: string,
  market_price: string,
  buy_type: string,
  description: boolean,
  rent_time: string,
  user_id: number,
  car_type_id: number,
  manufacturer_id: number,
  car_level_id: number,
  stocks: number,
  status: number,
  is_like: number,
  engine_type_id: number,
  price: number,
  sale_price: string | number,
  received_date: string,
}

export interface ContainerFAQState {
  cars: IResponsesCommon<ICar>
}

export const initialState: ContainerFAQState = {
  cars: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as ICar[]
    }
  } as IResponsesCommon<ICar>
}

const carSlice = createSlice({
  name: 'car-state',
  initialState,
  reducers: {
    //list
    getListCar(state, action: PayloadAction<any>) { },
    getListCarSuccess: (state, action: PayloadAction<IResponsesCommon<ICar>>) => {
      state.cars = action.payload
    },
  }
})

export const { getListCar, getListCarSuccess } = carSlice.actions

export default carSlice.reducer
