import { call, put, takeLatest} from 'redux-saga/effects'
import {
  getDealerDetail,
  getDealerDetailSuccess,
  getListDealers,
  getListDealersSuccess
} from './slice'
import {
  getUserAPI,
  getUserDetailAPI
} from 'app/services/userService'
import {DEALER_ROLE} from '../../../../../contants'

export function* dealerSaga() {
  //list
  yield takeLatest(getListDealers.type, function* dealersRequested(action: any) {
    try {
      const { data } = yield call(getUserAPI, action.payload, DEALER_ROLE, 'manufacturer');
      yield put(getListDealersSuccess(data));
    } catch (err) {}
  })

  yield takeLatest(getDealerDetail.type, function* dealerDetailRequested(action: any) {
    try {
      const { data } = yield call(getUserDetailAPI, action.payload, 'manufacturer', 'dealer')
      yield put(getDealerDetailSuccess(data))
    } catch (err) {}
  })
}