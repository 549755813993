import {call, put, takeLatest} from 'redux-saga/effects'
import { getListQuotes, getListQuoteSuccess } from './slice'
import {getCompareQuoteAPI} from 'app/services/compareQuoteService'

export function* quotesSaga() {
  yield takeLatest(getListQuotes.type, function* getListQuoteRequested(action: any) {
    try {
      const { data } = yield call(getCompareQuoteAPI, action.payload)
      yield put(getListQuoteSuccess(data))
    } catch (error: unknown) {

    }
  })
}